import React, { CSSProperties, FC, useContext, useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Checkbox,
  Divider,
  FormControlLabel,
  Menu,
  MenuItem,
  RadioGroup,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import './shared.scss';
import PublishIcon from '@mui/icons-material/Publish';
import { FormField, Label, TextEllipse } from './Ui';
import Autosuggest from 'react-autosuggest';
import { IdLabel, IdLabelIcon } from '../../interface/CommonType';
import Radio from '@mui/material/Radio';
import { NavigationButton } from './Buttons';
import { DatePicker, DatePickerSlotsComponentsProps, renderTimeViewClock, TimePicker } from '@mui/x-date-pickers';
import { GlobalContext } from '../../context/Global.context';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import NotificationService from '../../service/NotificationService';
import CmsIcon from './CmsIcon';
import { UI } from './index';

interface InputProps {
  // Le libellé du champ
  label?: string;
  // Le nom du champ
  name?: string;
  // Le style appliqué au FormField
  style?: React.CSSProperties;
  // Le style appliqué à l'input
  inputStyle?: React.CSSProperties;
  // Le champ est-il obligatoire ?
  required?: boolean;
  // Le texte à afficher dans le champ si celui-ci est vide
  placeholder?: string;
  // Les erreurs de validation (géré par react-hook-form)
  errors?: any;
  // La dimension horizontale de l'input (par défaut 100%)
  width?: string;
  // Fonction appelée lors de la modification de la valeur
  onChange?: any;
  // Le chanp est-il désactivé ?
  disabled?: boolean;
}

interface FormInputProps extends InputProps {
  // La référence de l'input (géré par react-hook-form)
  inputRef?: any;
}

interface CmsSwitchProps {
  // La valeur du switch
  value: boolean;
  // Le libellé du switch
  label?: string;
  // Fonction appelée lors de la modification de la valeur
  onChange: React.Dispatch<boolean>;
  // Le style appliqué au FormField
  style?: React.CSSProperties;
  // Le switch est-il en ligne ? (libellé à droite du switch)
  inline?: boolean;
  // Le nom du champ
  name?: string;
  // Le switch est-il désactivé ? (grisé)
  disabled?: boolean;
}

/**
 * Input de type Switch, équivalent à un bouton radio on/off
 * @param disabled Le switch est-il désactivé ? (grisé)
 * @param inline Le switch est-il en ligne ? (libellé à droite du switch)
 * @param label Le libellé du switch
 * @param name Le nom du champ
 * @param onChange Fonction appelée lors de la modification de la valeur
 * @param style Le style appliqué au FormField
 * @param value La valeur du switch
 */
export const CmsSwitch: FC<CmsSwitchProps> = ({
  disabled = false,
  inline = false,
  label,
  name,
  onChange,
  style,
  value,
}) => {
  return (
    <FormField style={style} className={inline ? 'inline-form-field' : ''}>
      <Label label={label} name={name} />
      <Switch color="primary" checked={value ?? false} onChange={() => onChange(!value)} disabled={disabled} />
    </FormField>
  );
};

interface InputFileProps {
  // La référence de l'input (géré par react-hook-form)
  inputRef?: any;
  // Le nom du champ
  name?: string;
  // Le libellé du champ
  label?: string;
  // Le libellé du bouton de sélection (par défaut 'Sélectionner')
  buttonLabel?: string;
  // Le champ est-il obligatoire ?
  required?: boolean;
  // Fonction appelée lors de la sélection d'un fichier
  onFileSelected?: any;
  // L'id du champ
  id?: string;
  // La hauteur du champ (par défaut 40px)
  height?: string;
  // Le champ est-il un champ d'upload d'image ?
  image?: boolean;
  // Le champ est-il un champ d'upload de pdf ?
  pdfOnly?: boolean;
  // Le champ est-il un champ d'upload d'image jpg ?
  jpgOnly?: boolean;
  // Le champ est-il désactivé ? (grisé)
  disabled?: boolean;
  // Le champ est-il un champ de sélection de plusieurs fichiers ?
  multiple?: boolean;
  // Fonction appelée lors du vidage du champ
  onClear?: any;
  // Le style appliqué au FormField (ou div contant l'input)
  style?: CSSProperties;
}

// Input de type fichier upload
/**
 * Input de type téléversement de fichier
 * @param buttonLabel Le libellé du bouton de sélection (par défaut 'Sélectionner')
 * @param disabled Le champ est-il désactivé ? (grisé)
 * @param height La hauteur du champ (par défaut 40px)
 * @param id L'id du champ
 * @param image Le champ est-il un champ d'upload d'image ?
 * @param inputRef La référence de l'input (géré par react-hook-form)
 * @param jpgOnly Le champ est-il un champ d'upload d'image jpg ?
 * @param label Le libellé du champ
 * @param multiple Le champ est-il un champ de sélection de plusieurs fichiers ?
 * @param name Le nom du champ
 * @param onClear Fonction appelée lors du vidage du champ
 * @param onFileSelected Fonction appelée lors de la sélection d'un fichier
 * @param pdfOnly Le champ est-il un champ d'upload de pdf ?
 * @param required Le champ est-il obligatoire ?
 * @param style Le style appliqué au FormField (ou div contant l'input)
 */
export const InputFile: FC<InputFileProps> = ({
  buttonLabel = 'Sélectionner',
  disabled = false,
  height = '40px',
  id = '',
  image = false,
  inputRef,
  jpgOnly = false,
  label,
  multiple = false,
  name,
  onClear,
  onFileSelected,
  pdfOnly = false,
  required = false,
  style,
}) => {
  const [fileName, setFileName] = useState<string>();
  const [files, setFiles] = React.useState<any>([]);

  function handleFileSelected(e?: React.ChangeEvent<HTMLInputElement>): void {
    let fileList = e?.target?.files;
    if (!fileList || fileList.length === 0) return setFileName(buttonLabel);
    setFiles(fileList);
    setFileName(fileList.length === 1 ? fileList[0].name : 'Plusieurs fichiers sélectionnés');
    if (!!onFileSelected) onFileSelected(multiple ? fileList : fileList[0]);
  }

  const handleClear = () => {
    setFiles([]);
    setFileName(undefined);
    if (!!onClear) onClear();
  };

  let buttonText = buttonLabel;
  if (fileName) buttonText = fileName;
  let accept = '*';
  if (pdfOnly) accept = 'application/pdf';
  else if (jpgOnly) accept = 'image/jpeg';
  else if (image) accept = 'image/*';

  const onDropHandler = (ev: any) => {
    ev.preventDefault();
    let newFileList = [...ev.dataTransfer.items].filter((item: any) => item.kind === 'file').map((x) => x.getAsFile());
    if (isFileError(newFileList, pdfOnly, jpgOnly, image))
      return NotificationService.error("Le type de fichier n'est pas valide");
    const payload = newFileList.length === 0 ? [] : multiple ? [...files, ...newFileList] : [newFileList[0]];
    setFiles(payload);
    setFileName(payload.length > 1 ? 'Plusieurs fichiers sélectionnés' : payload[0]?.name);
    if (onFileSelected) onFileSelected(multiple ? payload : payload[0]);
    NotificationService.info("dépot d'un fichier");
  };

  const onDragOver = (ev: any) => ev.preventDefault();

  return (
    <div style={{ maxWidth: '100%', ...style }} onDrop={onDropHandler} onDragOver={onDragOver}>
      <Label label={label} name={name} required={required} />
      <input
        accept={accept}
        disabled={disabled}
        style={{ display: 'none' }}
        id={'contained-button-file-' + id}
        ref={inputRef}
        name={name}
        multiple={multiple}
        onChange={handleFileSelected}
        type="file"
      />
      <label htmlFor={'contained-button-file-' + id}>
        <Button
          variant="outlined"
          disabled={disabled}
          component="span"
          style={{
            width: '100%',
            height: height,
            justifyContent: 'start',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <PublishIcon style={{ marginRight: '4px' }} />
          <TextEllipse text={buttonText} style={{ marginTop: '2px' }} />
          {!!onClear && <CmsIcon icon="close" style={{ marginLeft: 'auto' }} onClick={handleClear} />}
        </Button>
      </label>
    </div>
  );
};

function isFileError(list: File[], pdfOnly: boolean, jpgOnly: boolean, imageOnly: boolean): boolean {
  if (list.length === 0) return false;
  if (pdfOnly) return list.some((file) => file.type !== 'application/pdf');
  if (jpgOnly) return list.some((file) => file.type !== 'image/jpeg');
  if (imageOnly) return list.some((file) => !file.type.startsWith('image/'));
  return false;
}

interface CMSTimePickerProps {
  // Le libellé du champ
  label?: string;
  // Fonction appelée lors de la sélection d'une date
  onChange?: any;
  // La valeur du champ
  value?: Date | null;
  // Le champ est-il en erreur ? (date invalide)
  error?: boolean;
  // Le champ est-il désactivé ? (grisé)
  disabled?: boolean;
  // Le champ est-il nullable ?
  clearable?: boolean;
  onAccept?: any;
  className?: string;
  readOnly?: boolean;
  // Le champ n'est pas modifiable sous certaine condition
}

/**
 * Input de type calendrier
 * @param clearable Le champ est-il nullable ?
 * @param disabled Le champ est-il désactivé ? (grisé)
 * @param error Le champ est-il en erreur ? (date invalide)
 * @param label Le libellé du champ
 * @param onChange Fonction appelée lors de la sélection d'une date
 * @param value La valeur du champ
 * @param onAccept Fonction appelée lors de la validation de la date
 * @param className Classe CSS
 */
export const CMSTimePicker: FC<CMSTimePickerProps> = ({
  clearable = true,
  disabled = false,
  error,
  label,
  onChange,
  value,
  onAccept,
  className = '',
  readOnly,
}) => (
  <TimePicker
    viewRenderers={{
      hours: renderTimeViewClock,
      minutes: renderTimeViewClock,
      seconds: renderTimeViewClock,
    }}
    disabled={disabled}
    className={'time-picker ' + className + (error ? ' Mui-error' : '')}
    label={label}
    ampm={false}
    value={value ?? Date.now()}
    onChange={onChange}
    onAccept={onAccept}
    readOnly={readOnly}
  />
);

interface SuggestProps extends FormInputProps {
  // Clé de l'input
  key?: string;
  // Fonction appelée lors de la sélection d'une suggestion
  onValueSelected: any;
  // La valeur initiale du champ
  initialValue?: string;
  // Fonction appelée lors de la sélection d'une suggestion, permet de récupérer les suggestions depuis le backend
  getSuggestionService: (value: any) => Promise<any[]>;
  // L'attribut à afficher dans la liste de suggestions (liste d'objet renvoyé par le backend)
  showAttribute: string;
  // L'attribut à renvoyer au parent au moment d'une selection, par défaut renvoie l'objet complet
  getAttribute?: string;
  // Valeur à afficher dans le champ si celui-ci est vide (par défaut 'auto-complétion')
  placeHolder?: string;
  // Permet de savoir si l'input est rempli ou non même s'il n'a pas selectioné de suggestion
  isInputFilledListener?: any;
}

// Style du compsant auto-suggest
const themeAutoSuggest = {
  container: {
    position: 'relative',
  },
  input: {
    width: '100%',
    maxHeight: '40px',
    caretColor: '#ddd',
    color: '#ddd',
    padding: '10px 20px',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 300,
    fontSize: 16,
    border: '1px solid rgb(133,133,133)',
    backgroundColor: 'transparent',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  inputFocused: {
    outline: 'none',
    border: '2px solid #61daff',
  },
  inputOpen: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  suggestionsContainer: {
    display: 'none',
  },
  suggestionsContainerOpen: {
    display: 'block',
    position: 'absolute',
    top: 30,
    width: '100%',
    minWidth: '200px',
    maxHeight: '300px',
    overflowY: 'auto',
    border: '1px solid #aaa',
    backgroundColor: 'grey',
    fontFamily: 'Helvetica, sans-serif',
    fontWeight: 300,
    fontSize: 16,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    zIndex: 2,
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  suggestion: {
    cursor: 'pointer',
    padding: '10px 20px',
  },
  suggestionHighlighted: {
    backgroundColor: 'grey',
  },
};

/**
 * Input type Texte avec proposition (liste) calculé côté backend
 * @param getAttribute L'attribut à renvoyer au parent au moment d'une selection, par défaut renvoie l'objet complet
 * @param getSuggestionService Fonction appelée lors de la sélection d'une suggestion, permet de récupérer les suggestions depuis le backend
 * @param initialValue La valeur initiale du champ
 * @param isInputFilledListener Permet de savoir si l'input est rempli ou non même s'il n'a pas selectioné de suggestion
 * @param label Le libellé du champ
 * @param name Le nom du champ
 * @param onValueSelected Fonction appelée lors de la sélection d'une suggestion
 * @param placeHolder Valeur à afficher dans le champ si celui-ci est vide (par défaut 'auto-complétion')
 * @param showAttribute L'attribut à afficher dans la liste de suggestions (liste d'objet renvoyé par le backend)
 */
export const CustomSuggest: FC<SuggestProps> = ({
  key,
  getAttribute,
  getSuggestionService,
  initialValue,
  isInputFilledListener = null,
  label,
  name,
  onValueSelected,
  disabled,
  placeHolder,
  showAttribute,
}) => {
  const { theming } = useContext(GlobalContext);
  const [value, setValue] = useState(initialValue?.toString() ?? '');
  const [valueIsSelected, setValueIsSelected] = useState<any>(!!initialValue);
  const [timeRender, setTimeRender] = useState<any>(null);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [itemList, setItemList] = useState<Array<any>>([]);

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue?.toString() ?? '');
      setValueIsSelected(true);
    }
  }, [initialValue, showAttribute]);

  function getSuggestions(val: string) {
    if (timeRender) clearTimeout(timeRender);
    if (val === value) return;
    setValueIsSelected(false);
    if (valueIsSelected) return;
    setValue(val);
    const timeOut = setTimeout(() => {
      getSuggestionService(val).then((result: any) => {
        const list: string[] = result.map((item: any) => item[showAttribute]);
        setItemList(result);
        setSuggestions(list);
      });
    }, 500);
    setTimeRender(timeOut);
  }

  const reset = () => {
    setValue('');
    if (valueIsSelected) setValueIsSelected(false);
    onValueSelected(undefined);
  };

  themeAutoSuggest.suggestionsContainerOpen.backgroundColor = theming.get().cms.container.primary;
  themeAutoSuggest.suggestionHighlighted.backgroundColor = theming.get().cms.container.secondary;
  themeAutoSuggest.input.color = theming.get().palette.text.primary;
  themeAutoSuggest.input.caretColor = theming.get().palette.text.primary;

  return (
    <div key={key} className="suggest-container">
      {!!label && <Label label={label} name={name} />}
      <Autosuggest
        key={'suggest' + key}
        id={name}
        theme={themeAutoSuggest as any}
        suggestions={suggestions}
        onSuggestionsClearRequested={() => setSuggestions([])}
        onSuggestionsFetchRequested={() => {}}
        onSuggestionSelected={(_, { suggestionValue }) => {
          const item: any = itemList.find((item: any) => item[showAttribute] === suggestionValue);
          onValueSelected(getAttribute ? item[getAttribute] : item, item);
          setValue(suggestionValue);
          setValueIsSelected(true);
        }}
        getSuggestionValue={(suggestion) => suggestion}
        renderSuggestion={(suggestion) => <span>{suggestion}</span>}
        inputProps={{
          placeholder: placeHolder ?? 'auto-complétion',
          disabled: disabled,
          value: value,
          onBlur: () => !valueIsSelected && reset(),
          onChange: (_, { newValue, method }) => {
            if (method !== 'click') getSuggestions(newValue);
            if (isInputFilledListener != null) isInputFilledListener(newValue && newValue !== '');
          },
        }}
        highlightFirstSuggestion={true}
      />
      {valueIsSelected && <CmsIcon className="suggest-close" icon="close" onClick={reset} />}
    </div>
  );
};

interface DatePickerProps {
  // Valeur du champ (date)
  value?: Date | string | null;
  // Fonction appelée lors de la modification de la valeur du champ
  onChange: any;
  // Hauteur du champ (par défaut 40px)
  height?: string;
  // Le champ est-il en erreur ? (date invalide par exemple)
  error?: any;
  // Référence de l'input
  inputRef?: any;
  // Libellé du champ
  label?: string;
  // Le libellé avec une police plus grande au dessus du composant
  customLabel?: boolean;
  // Libellé du champ lorsqu'il est vide
  // placeholder?: string;
  // // Nom du champ
  // name?: string;
  // le champ peut-il être null ? (par défaut il peut être vide)
  notNullable?: boolean;
  // Style du champ
  // style?: React.CSSProperties;
  // Le champ est-il en lecture seule ?
  readOnly?: boolean;
  // Le champ est-il désactivé ? (grisé)
  disabled?: boolean;
  // Le champ doit-il renvoyer une date ou une date et une heure ? (par défaut renvoie une date à minuit)
  dateOnly?: boolean;
  // Format de la date (par défaut dd/MM/yyyy)
  format?: string;
  containerStyle?: CSSProperties;
  minDate?: Date;
  maxDate?: Date;
}

/**
 * Input de type calendrier avec un calendrier visuel pour selectionner une date
 * @param value La valeur du champ (date)
 * @param inputRef Référence de l'input
 * @param label Le libellé du champ
 * @param customLabel le label avec une police plus grande
 * @param onChange Fonction appelée lors de la modification de la valeur du champ
 * @param height Hauteur du champ (par défaut 40px)
 * @param notNullable le champ peut-il être null ? (par défaut il peut être vide)
 * @param readOnly Le champ est-il en lecture seule ?
 * @param disabled Le champ est-il désactivé ? (grisé)
 * @param dateOnly Le champ doit-il renvoyer une date ou une date et une heure ? (par défaut renvoie une date à minuit)
 * @param format Format de la date (par défaut dd/MM/yyyy)
 */
export const DatePickerVanilla: FC<DatePickerProps> = ({
  value,
  inputRef,
  label,
  customLabel = false,
  onChange,
  notNullable = false,
  readOnly = false,
  disabled = false,
  dateOnly = true,
  format = 'dd/MM/yyyy',
  containerStyle,
  error,
  minDate,
  maxDate,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [localValue, setLocalValue] = useState(value);
  const handleChange = (date: any, endFocus = false) => {
    if (!endFocus) setLocalValue(date);
    if (isFocused && !endFocus) return;
    let finalDate: any = date;
    if (!!date && isNaN(Date.parse(date))) return;
    if (notNullable && (!date || typeof date == 'string')) return;
    if (notNullable && (date < new Date('1900-01-01') || date > new Date('2100-01-01'))) return;
    if (date === value) return;
    if (!notNullable && (!date || typeof date == 'string')) finalDate = null;
    if (!!finalDate && dateOnly) finalDate.setHours(0, 0, 0, 0);
    onChange(finalDate);
  };
  const handleBlur = () => {
    setIsFocused(false);
    handleChange(localValue, true);
  };
  if (!localValue && value) setLocalValue(value);
  const onKeyDown = (e: any) => e.key === 'Enter' && handleBlur();
  let clear: DatePickerSlotsComponentsProps<Date> | undefined = { actionBar: { actions: ['clear'] } };
  if (notNullable) clear = undefined;
  if (value === undefined) value = null;
  return (
    <div style={containerStyle} className="cms-date-picker">
      {customLabel && <UI.LabelWithError label={label} name={''} />}
      <DatePicker
        format={format}
        className={'cms-date-picker' + (error ? ' Mui-error' : '')}
        value={typeof value === 'string' ? new Date(value) : value}
        label={!customLabel ? label : ''}
        onChange={(x) => handleChange(x)}
        readOnly={readOnly}
        inputRef={inputRef}
        disabled={disabled}
        minDate={minDate ?? new Date('1900-01-01')}
        maxDate={maxDate ?? new Date('2100-01-01')}
        slotProps={{
          ...clear,
          field: { onBlur: handleBlur, onKeyDown, onFocus: () => setIsFocused(true) } as any,
        }}
      />
    </div>
  );
};

interface DatePickerWithLabelProps extends DatePickerProps {
  name?: string;
}

/**
 * Input de type calendrier avec le format FormField (label + input)
 * @param props Les props du DatePickerVanilla, se référer à la documentation de ce composant
 */
export const DatePickerWithLabel: FC<DatePickerWithLabelProps> = (props) => {
  return (
    <FormField>
      <Label label={props.label} name={props.name} />
      <DatePickerVanilla {...props} label={undefined} />
    </FormField>
  );
};

interface CalendarProps {
  // Date sélectionnée
  date?: Date;
  // Fonction appelée lors de la modification de la date
  onChange: any;
  // Valide la sélection automatiquement
  autoOk?: boolean;
  // Désactive les dates passées
  disablePast?: boolean;
  // Désactive les dates futures
  disableFuture?: boolean;
  // Le champ est-il en lecture seule ?
  readOnly?: boolean;
}

/**
 * Calendrier fixe complétement afficher (sans input texte)
 * @param autoOk Valide la sélection automatiquement
 * @param date Date sélectionnée
 * @param disableFuture Désactive les dates futures
 * @param disablePast Désactive les dates passées
 * @param onChange Fonction appelée lors de la modification de la date
 * @param readOnly Le champ est-il en lecture seule ?
 */
export const CMSCalendar: FC<CalendarProps> = ({
  autoOk = true,
  date,
  disableFuture = false,
  disablePast = false,
  onChange,
  readOnly = false,
}) => {
  const handleDate = (date: Date | null) => {
    const result = date?.setHours(0, -(date?.getTimezoneOffset() ?? 0), 0, 0);
    onChange(result ? new Date(result) : null);
  };
  return (
    <DatePicker
      disablePast={disablePast}
      disableFuture={disableFuture}
      value={date}
      readOnly={readOnly}
      onChange={handleDate}
    />
  );
};

interface SimpleSelectProps {
  // Valeur sélectionnée
  value: any;
  // Libellé du champ
  label?: string;
  // Nom du champ
  name?: string;
  // Style du FormField
  style?: React.CSSProperties;
  // Style de l'input
  itemStyle?: React.CSSProperties;
  // Liste d'options à afficher (IdLabel[] | IdLabelIcon[])
  options?: Array<IdLabelIcon | IdLabel>;
  // Fonction appelée lors de la modification de la valeur
  onChange: any;
  // Le champ est-il désactivé ? (grisé)
  disabled?: boolean;
  // Le champ peut-il être null ?
  nullable?: boolean;
  // Référence de l'input (pour les formulaires)
  inputRef?: any;
  className?: string;
}

/**
 * Input de type select avec une liste de proposition préalablement défini
 * Liste d'options de type IdLabel[] | IdLabelIcon[]
 * @param disabled Le champ est-il désactivé ? (grisé)
 * @param inputRef Référence de l'input (pour les formulaires)
 * @param itemStyle Style de l'input
 * @param label Libellé du champ
 * @param name Nom du champ
 * @param nullable Le champ peut-il être null ?
 * @param onChange Fonction appelée lors de la modification de la valeur
 * @param options Liste d'options à afficher (IdLabel[] | IdLabelIcon[])
 * @param style Style du FormField
 * @param value Valeur sélectionnée
 * @param className Classe CSS
 */
export const SimpleSelect: FC<SimpleSelectProps> = ({
  disabled = false,
  inputRef,
  itemStyle,
  label,
  name,
  nullable = false,
  onChange,
  options,
  style,
  value,
  className,
}) => {
  if (nullable && options) options = [{ id: null, label: 'Aucun' }, ...options];
  return (
    <FormField className={className} style={style}>
      {label && <Label label={label} name={'simple-select' + name ?? ''} />}
      <Select
        labelId="simple-select"
        name={name}
        id={'simple-select' + name ?? ''}
        variant="outlined"
        disabled={disabled || !options}
        style={{ width: '100%' }}
        value={value ?? ''}
        inputRef={inputRef}
        onChange={(x) => onChange(x?.target?.value)}
        inputProps={{ name }}
      >
        {options &&
          options.map((opt, i) => (
            <MenuItem key={i} value={opt.id} style={itemStyle}>
              {'icon' in opt && opt.icon}
              {opt.label}
            </MenuItem>
          ))}
      </Select>
    </FormField>
  );
};

interface CMSTextFieldProps {
  // Libellé du champ
  label?: string;
  // Nom du champ
  name?: string;
  // Fonction appelée lors de la modification de la valeur
  onChange: any;
  // Largeur du champ (100% par défaut)
  width?: number | string;
  // Valeur du champ
  value?: string | number;
  // La valeur à afficher lorsque le champ est vide
  placeholder?: string;
  // Style de l'intput
  style?: React.CSSProperties;
  // Le champ est-il obligatoire ?
  required?: boolean;
  // Le champ est-il multiligne ? (Textarea)
  multiline?: boolean;
  // Le champ est-il désactivé ? (grisé)
  disabled?: boolean;
  // Liste des erreurs à afficher (contour rouge)
  errors?: any;
  // Props supplémentaires pour l'input (surcharge)
  InputProps?: any;
  // Type de l'input (string | number)
  type?: 'string' | 'number' | 'password';
  // Le champ est-il en lecture seule ?
  readOnly?: boolean;
  // Lorsque l'on appuie sur une touche
  onKeyPress?: any;
  className?: string;
}

/**
 * Input de type texte (champ générique pour les type textes)
 * @param InputProps Props supplémentaires pour l'input (surcharge)
 * @param disabled Le champ est-il désactivé ? (grisé)
 * @param errors Liste des erreurs à afficher (contour rouge)
 * @param label Libellé du champ
 * @param multiline Le champ est-il multiligne ? (Textarea)
 * @param name Nom du champ
 * @param onChange Fonction appelée lors de la modification de la valeur
 * @param placeholder La valeur à afficher lorsque le champ est vide
 * @param readOnly Le champ est-il en lecture seule ?
 * @param required Le champ est-il obligatoire ?
 * @param style Style de l'intput
 * @param type Type de l'input (string | number)
 * @param value Valeur du champ
 * @param width Largeur du champ (100% par défaut)
 * @param onKeyPress Fonction appelée lors de l'appui sur une touche
 * @param className Classe CSS
 */
export const CMSTextField: FC<CMSTextFieldProps> = ({
  InputProps,
  disabled = false,
  errors,
  label,
  multiline = false,
  name = '',
  onChange,
  placeholder,
  readOnly = false,
  required = false,
  style,
  type = 'string',
  value,
  width = '100%',
  onKeyPress,
  className,
}) => {
  return (
    <FormField>
      <Label label={label} name={name} required={required} />
      <TextField
        InputProps={InputProps}
        id={name}
        name={name}
        type={type}
        multiline={multiline}
        value={value ?? null}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
        required={required}
        disabled={disabled}
        aria-readonly={readOnly}
        variant="outlined"
        size="small"
        className={className}
        onKeyPress={onKeyPress}
        style={{ width, ...style }}
        {...handleErrors(name, errors)}
      />
    </FormField>
  );
};

/**
 * Gestion des erreurs, permet d'afficher un message d'erreur et de mettre le contour du champ en rouge
 * @param name Nom du champ
 * @param errors Liste des erreurs à afficher (contour rouge)
 */
const handleErrors = (name: string, errors: any) => {
  const error = errors && errors[name] !== undefined;
  const helperText = error ? errors[name]?.message : '';
  return { error, helperText };
};

interface CmsRadioProps {
  // Valeur par défaut
  defaultValue: string | number;
  // Valeur sélectionnée
  value: string | number;
  // Fonction appelée lors de la modification de la valeur
  onChange: any;
  // Liste d'options à afficher (IdLabel[])
  options: IdLabel[];
  // Classe CSS à donner au composant
  className?: string;
  // Affichage avec libellé à côté des boutons radio ou au-dessus
  inline?: boolean;
}

/**
 * Bouton radio, Liste d'options de type IdLabel[]
 * @param inline Affichage avec libellé à côté des boutons radio ou au-dessus
 * @param className Classe CSS à donner au composant
 * @param defaultValue Valeur par défaut
 * @param value Valeur sélectionnée
 * @param onChange Fonction appelée lors de la modification de la valeur
 * @param options Liste d'options à afficher (IdLabel[])
 */
export const CmsRadio: FC<CmsRadioProps> = ({ inline = false, className, defaultValue, value, onChange, options }) => {
  const retype = (value: any) => (isNaN(value) ? value : +value);
  const style: CSSProperties = inline ? { flexDirection: 'row' } : {};
  return (
    <RadioGroup
      style={{ display: 'flex', ...style }}
      className={className}
      defaultValue={defaultValue}
      aria-label="radiogroup"
      name="radiogroup"
      value={value ?? null}
      onChange={(x) => onChange(retype(x.target.value))}
    >
      {options &&
        options.map((option: IdLabel, i: number) => (
          <FormControlLabel key={i} control={<Radio />} label={option.label} value={option.id} />
        ))}
    </RadioGroup>
  );
};

interface ButtonValidationProps {
  // Fonction à appeler lors de la validation du formulaire
  onValidate: any;
  // Fonction à appeler lors de la suppression d'un élément (HttpDelete)
  onDelete?: any;
  // Défini si le formulaire est en train de soumettre la requête (utile pour empêcher les doublons)
  isSubmitting: boolean;
  // Défini si le formulaire est valide ou non
  // Utile pour bloquer les action submit si le formulaire n'est pas valide/complet
  isValid: boolean;
  // Défini si le formulaire est en mode création ou édition
  isFormUpdate: boolean;
  // Url de base du composant (pour les redirections)
  componentBaseUrl: string;
  // ne pas affichier le divider
  withoutDivider?: boolean;
  // Fonction à appeler lors de la validation du formulaire et redirection vers la liste
  onValidateAndGoList?: any;
}

/**
 * Boutons de validation d'un formulaire n'utilisant PAS le hook useForm
 * @param onValidate Fonction à appeler lors de la validation du formulaire
 * @param isSubmitting Défini si le formulaire est en train de soumettre la requête (utile pour empêcher les doublons)
 * @param isValid Défini si le formulaire est valide ou non
 * @param isFormUpdate Défini si le formulaire est en mode création ou édition
 * @param componentBaseUrl Url de base du composant (pour les redirections)
 * @param onDelete Fonction à appeler lors de la suppression d'un élément (HttpDelete)
 * @param onValidateAndGoList Fonction à appeler lors de la validation du formulaire et redirection vers la liste
 * @param withoutDivider ne pas afficher le divider
 */
export const ButtonValidation: FC<ButtonValidationProps> = ({
  onValidate,
  isSubmitting,
  isValid,
  isFormUpdate,
  componentBaseUrl,
  onDelete,
  onValidateAndGoList,
  withoutDivider = false,
}) => (
  <>
    {!withoutDivider && <Divider />}
    <div css={{ '& button': { margin: '0 0.5em 0.5em 0' }, '& a': { margin: '0 0.5em 0.5em 0' } }}>
      <>
        <Button color="primary" variant="outlined" onClick={onValidate} disabled={isSubmitting || !isValid}>
          {isFormUpdate ? 'Mettre à jour' : 'Créer'}
        </Button>
        <Button color="primary" variant="outlined" onClick={onValidateAndGoList} disabled={isSubmitting || !isValid}>
          {isFormUpdate ? 'Mettre à jour' : 'Créer'} et retourner à la liste
        </Button>
        {onDelete && isFormUpdate && (
          <Button
            style={{ borderColor: '#f44336', color: '#f44336' }}
            variant="outlined"
            onClick={onDelete}
            disabled={isSubmitting}
          >
            Supprimer
          </Button>
        )}
      </>
      <NavigationButton size="medium" title="Abandonner" color="warning" to={`${componentBaseUrl}list`} />
    </div>
  </>
);

interface CmsButtonGroupProps {
  options: IdLabel[];
  value?: any;
  onClick: (x: any) => any;
  showActive?: boolean;
  style?: CSSProperties;
}

export const CmsButtonGroup: FC<CmsButtonGroupProps> = ({ options, value, onClick, style }) => {
  return (
    <ButtonGroup variant="outlined" style={style}>
      {options.map((option: IdLabel, i: number) => (
        <Button
          size="small"
          key={i}
          onClick={() => onClick(option.id)}
          color={value === option.id ? 'primary' : 'inherit'}
        >
          {option.label}
        </Button>
      ))}
    </ButtonGroup>
  );
};

interface MenuWithCheckBoxProps {
  children?: React.ReactNode;
  options: IdLabel[];
  value: any[];
  onClick: (x: any) => any;
  containerStyle?: CSSProperties;
}

export const MenuWithCheckBox: FC<MenuWithCheckBoxProps> = ({ children, options, value, onClick, containerStyle }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  return (
    <span style={containerStyle}>
      <div style={{ cursor: 'pointer' }} onClick={(e) => setAnchorEl(e.currentTarget)}>
        {children}
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
      >
        {options.map((option: IdLabel, i: number) => (
          <MenuItem key={i} onClick={() => onClick(option.id)}>
            <ListItemIcon>
              <Checkbox checked={value.includes(option.id)} color="primary" />
            </ListItemIcon>
            <ListItemText>{option.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </span>
  );
};
