import React, { FC } from 'react';
import AccessFilter from '../../../helper/AccessFilter';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import APIRoute from '../../../constant/API.constant';
import { WkReportItem } from '../../../interface/WkType';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import ROLE from '../../../constant/role.constant';

export const WkReportItemListConfig: CmsColumnDef<WkReportItem>[] = [
  { header: 'Nom', id: 'name', Filter: CmsTableFilter.Text },
  { header: 'Catégorie', id: 'categoryName', Filter: CmsTableFilter.Text },
  { header: 'Active', id: 'active', Filter: CmsTableFilter.Bool },
];

/**
 * Liste des lignes de rapport d'intervention
 */
export const WkReportItemList: FC = () => {
  const actions = AccessFilter([ROLE.ADMIN_CONFIGURATION_REPORTITEM_CREATE])
    ? [<AddNavigationButton title="Ajouter une ligne de rapport d'intervention" to="/castres/work/reportitem/create" />]
    : [];
  return (
    <CmsFrontendTable
      title="Liste des lignes de rapport d'intervention"
      route={APIRoute.WkReportItem}
      columns={WkReportItemListConfig}
      navigateTo={(id: number) => `/castres/work/reportitem/${id}/edit`}
      actions={actions}
      setFiltersInUrl
    />
  );
};

/**
 * Création ou modification d'une ligne de constat
 * @param id Id de la ligne de constat
 * @param setHttpCodePage Fonction pour modifier le code de la page
 */
export const WkReportItemCreateEdit: FC = ({ id }: any) => (
  <CmsForm
    id={id}
    currentUrl="/castres/work/reportitem/"
    route={APIRoute.WkReportItem}
    title="une ligne de constat"
    thin
  >
    <CmsFormInput.Text id="name" label="Nom" required max={50} />
    <CmsFormInput.Text id="description" label="Description" multiline />
    <CmsFormInput.Select id="categoryId" label="Catégorie" options={APIRoute.WkReportCategory + '/Simplified'} />
    <CmsFormInput.Switch id="active" label="Active" inline />
  </CmsForm>
);
