import React, { CSSProperties, FC, useContext, useEffect } from 'react';
import CRUD from '../../../service/CRUD.service';
import { BlQuotation, BlQuotationAttachment, BlQuotationLine } from '../../../interface/BlType';
import APIRoute from '../../../constant/API.constant';
import { CmsLink, CmsPaper, HeaderPanel, TextOverflow, WarningBubble } from '../../../component/shared/Ui';
import { LabelValueIf, ListStyleDataView, TabStyleDataView } from '../../../component/shared/TabStyleDataView';
import { ArrowBackNavigationButton, EditNavigationButton, NavigationButton } from '../../../component/shared/Buttons';
import ROLE from '../../../constant/role.constant';
import { TableContainer } from '@mui/material';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { ClClient, ClContact } from '../../../interface/ClType';
import './quotation.scss';
import LoadingScreen from '../../../component/LoadingScreen';
import { getChapterAmount } from './QuotationCreateEdit';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { API_URL } from '../../../constant/API_URL';
import Utils from '../../../helper/Utils';

interface QuotationContextProps {
  quotation: BlQuotation;
}

const QuotationContext = React.createContext<QuotationContextProps>({
  quotation: {} as BlQuotation,
});

export const QuotationShow: FC<any> = ({ id }) => {
  const [quotation, setQuotation] = React.useState<any>();

  useEffect(() => {
    CRUD.getById<BlQuotation>(APIRoute.BlQuotation, id).then((data) => {
      if (!data) return;
      let currentIdent = 0;
      for (let line of data.quotationLines ?? []) {
        if (line.chapter) {
          currentIdent = line.chapterLevel ?? 0;
          line.chapterAmount = getChapterAmount(data.quotationLines ?? [], line.id ?? -1, 'totalAmount');
        }
        line.indent = currentIdent;
      }
      setQuotation(data);
    });
  }, [id]);

  if (!quotation) return <LoadingScreen />;
  return (
    <QuotationContext.Provider value={{ quotation }}>
      <QuotationHeader quotation={quotation} />
      <div className="quotation-show-container">
        <GeneralInfo />
        <SiteInfo />
        <Status />
        <StationPicture />
        <QuotationComment />
        <ClientInfo />
      </div>
      <QuotationPriceList />
    </QuotationContext.Provider>
  );
};

const Status: FC = () => {
  const { quotation } = useContext(QuotationContext);
  const style: CSSProperties = { textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold' };
  return (
    <CmsPaper className="status" title="Statut" tied>
      <WarningBubble style={style} customColor={'#' + (quotation.statusColor ?? '00AAFF')}>
        {quotation.statusLabel ?? 'En cours de création'}
      </WarningBubble>
    </CmsPaper>
  );
};

const StationPicture: FC = () => {
  const { quotation } = useContext(QuotationContext);
  const handleDownload = (attachment: BlQuotationAttachment) => {
    CRUD.getBlob(APIRoute.BlQuotation + '/GetAttachments', { id: attachment.id }).then((blob) => {
      console.log('attachement', attachment);
      Utils.downloadFile(blob, attachment.filename);
    });
  };
  return (
    <CmsPaper className="station-picture" title="Pièces jointes" tied>
      {quotation.imagePath && (
        <div className="flex-center">
          <LazyLoadImage
            alt="Image entete devis"
            effect="opacity"
            src={`${API_URL}${APIRoute.BlQuotation}/Pic/${quotation.imagePath}`}
            style={{
              maxHeight: '10rem',
              maxWidth: '100%',
              borderRadius: '0.3rem',
              boxShadow: '0 0 0.5rem 0.1rem rgba(0,0,0,0.3)',
            }}
          />
        </div>
      )}
      <ListStyleDataView
        title="Pièces Jointes"
        style={{ overflowY: 'auto', maxHeight: '20rem' }}
        fromList={quotation.blQuotationAttachment}
        cell={(x) => (
          <div className="flex-h-bet clickable">
            <div onClick={() => handleDownload(x)}>{x.filename}</div>
          </div>
        )}
      />
    </CmsPaper>
  );
};

const QuotationComment: FC = () => {
  const { quotation } = useContext(QuotationContext);
  return (
    <CmsPaper className="quotation-comment" title="Commentaire" tied>
      <TextOverflow text={quotation.comment} />
    </CmsPaper>
  );
};

// const QuotationStepper: FC = () => {
//   const [statusList, setStatusList] = React.useState<BlStatus[]>([]);
//   const { quotation } = useContext(QuotationContext);
//
//   useEffect(() => {
//     CRUD.getList<BlStatus>(APIRoute.BlStatus).then(setStatusList);
//   }, []);
//
//   if (statusList.length === 0) return <></>;
//   let activeStep = 0;
//   for (let s of statusList) if (s.code === quotation.statusCode) activeStep = s.position - 1;
//   return (
//     <CmsPaper>
//       <CmsStepper activeStep={activeStep} stepList={statusList} />
//     </CmsPaper>
//   );
// };

const QuotationHeader: FC<any> = ({ quotation }) => {
  return (
    <HeaderPanel
      title={'Devis N° ' + quotation.ref}
      actions={[
        <ArrowBackNavigationButton title="Retourner à la liste" to="/castres/billing/quotation/list" />,
        <NavigationButton
          to={`/castres/billing/quotation/${quotation.id}/print?hidePrice=true`}
          title="Imprimé sans prix"
        />,
        <NavigationButton to={`/castres/billing/quotation/${quotation.id}/print`} title="Imprimé" />,
        <NavigationButton to={`/castres/billing/quotation/${quotation.id}/duplicate`} title="Dupliqué" />,
        <EditNavigationButton
          access={[ROLE.ADMIN_QUOTATION_EDIT]}
          title="Éditer le devis"
          to={`/castres/billing/quotation/${quotation.id}/edit`}
        />,
      ]}
    />
  );
};

const GeneralConf: LabelValueIf[] = [
  { label: 'Référence', value: (x: BlQuotation) => x.ref },
  { label: 'En date du', value: (x: BlQuotation) => x.createdAt },
  { label: "Corps d'état", value: (x: BlQuotation) => 'TODO' },
  { label: 'Groupe', value: (x: BlQuotation) => x.group?.label },
  { label: 'Service', value: (x: BlQuotation) => x.service?.label },
  { label: 'Contrat', value: (x: BlQuotation) => 'TODO' },
  { label: 'Créer par', value: (x: BlQuotation) => x.createdBy?.nameReversed },
  { label: 'Modifier par', value: (x: BlQuotation) => x.updatedBy?.nameReversed },
  { label: 'Contenu modifié le', value: (x: BlQuotation) => x.updatedAt },
  { label: 'N° de commande', value: (x: BlQuotation) => 'TODO' },
  { label: 'Devis Excel', value: (x: BlQuotation) => 'TODO' },
];

const GeneralInfo: FC = () => {
  const { quotation } = useContext(QuotationContext);
  return (
    <CmsPaper className="general-info" title="General" tied>
      <TabStyleDataView conf={GeneralConf} mapFrom={quotation} />
    </CmsPaper>
  );
};

const ClientInfoConf: LabelValueIf[] = [
  {
    label: 'Client',
    value: (x: ClClient) => (
      <CmsLink href={`/castres/client/client/${x.id}/show`} label={x.label} target="_blank" defaultStyle />
    ),
  },
  {
    label: 'Adresse',
    value: (x: ClClient) =>
      `${x.address ?? 'non renseigné'}, ${x.postcode ?? 'non renseigné'}, ${x.city ?? 'non renseigné'}`,
  },
  { label: 'Tel', value: (x: ClClient) => x.tel },
  { label: 'Email', value: (x: ClClient) => x.email },
];

const ContactInfoConf: LabelValueIf[] = [
  {
    label: 'Destinataire',
    value: (x: ClContact) =>
      x.getFormattedName ? (
        <CmsLink
          href={`/castres/client/contact/${x.id}/show`}
          label={x.getFormattedName}
          target="_blank"
          defaultStyle
        />
      ) : (
        <></>
      ),
  },
  {
    label: 'Adresse',
    value: (x: ClContact) =>
      `${x.address ?? 'non renseigné'}, ${x.postcode ?? 'non renseigné'}, ${x.city ?? 'non renseigné'}`,
  },
  { label: 'Tel', value: (x: ClContact) => x.tel },
  { label: 'Email', value: (x: ClContact) => x.email },
];

const ClientInfo: FC = () => {
  const { quotation } = useContext(QuotationContext);
  return (
    <CmsPaper className="client-info" title="Destinataire" tied>
      <div className="client-contact-container">
        <div>
          <TabStyleDataView conf={ClientInfoConf} mapFrom={quotation.client} />
        </div>
        <div>
          <TabStyleDataView conf={ContactInfoConf} mapFrom={quotation.contact} />
        </div>
      </div>
    </CmsPaper>
  );
};

const SiteInfoConf: LabelValueIf[] = [
  {
    label: 'Intervention',
    value: (x: BlQuotation) => {
      if (!x.interventionId) return 'Aucune intervention associée';
      return (
        <CmsLink
          href={`/castres/work/intervention/${x.interventionId}/show`}
          label={x.interventionRef ?? ''}
          target="_blank"
          defaultStyle
        />
      );
    },
  },
  { label: 'Enseigne', value: (x: BlQuotation) => 'TODO' },
  {
    label: 'Site',
    value: (x: BlQuotation) => {
      if (!x.stationResource?.id) return 'Aucune Station associée';
      let label = x.stationResource.ref + '_' + x.stationResource?.label;
      return (
        <CmsLink
          href={`/castres/client/station/${x.stationResource.id}/show`}
          label={label}
          target="_blank"
          defaultStyle
        />
      );
    },
  },
  {
    label: 'Adresse',
    value: (x: BlQuotation) => {
      return `${x.stationResource?.address}, ${x.stationResource?.postcode}, ${x.stationResource?.city}`;
    },
  },
  { label: 'Facture', value: (x: BlQuotation) => 'TODO' },
];

const SiteInfo: FC = () => {
  const { quotation } = useContext(QuotationContext);
  return (
    <CmsPaper className="site-info" title="Site" tied>
      <TabStyleDataView conf={SiteInfoConf} mapFrom={quotation} />
    </CmsPaper>
  );
};

const tableConfig: any[] = [
  { label: 'Désignation', value: null },
  { label: 'Unité', value: null },
  { label: 'Quantité', value: null },
  { label: 'Prix Unitaire', value: null },
  { label: 'Réduction', value: null },
  { label: 'Montant', value: '3rem' },
  { label: 'TVA', value: '3rem' },
  { label: 'Montant H.T', value: '7rem' },
  { label: 'Facturé / Comptabilisé', value: '6rem' },
  { label: 'Dont avoirs', value: '6rem' },
];

const QuotationPriceList: FC = () => {
  const { quotation } = useContext(QuotationContext);
  return (
    <CmsPaper title={'Devis N° ' + quotation.ref}>
      <div className="flex-h" style={{ marginBottom: '1rem' }}>
        <WarningBubble type="info" style={{ marginRight: '1rem' }}>
          Total H.T: {Utils.ThousandSpacing(quotation.totalAmount ?? 0)} €
        </WarningBubble>
        <WarningBubble type="info" style={{ marginRight: '1rem' }}>
          TVA: {Utils.ThousandSpacing(quotation.totalTva ?? 0)} €
        </WarningBubble>
        <WarningBubble type="info">Total TTC: {Utils.ThousandSpacing(quotation.totalTtc ?? 0)} €</WarningBubble>
      </div>
      <TableContainer style={{ borderRadius: '6px', border: '2px solid #888', overflow: 'auto' }}>
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableConfig.map((column, i) => (
                <TableCell key={i} style={{ width: column.value ?? 'auto', backgroundColor: 'rgba(0,0,0,0.2)' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <Body />
        </Table>
      </TableContainer>
    </CmsPaper>
  );
};

const Body: FC<any> = () => {
  const { quotation } = useContext(QuotationContext);
  if (!quotation?.quotationLines) return <></>;
  return (
    <TableBody className="body-show">
      {quotation.quotationLines.map((line, i) => {
        if (line.chapter) return <ChapterRow line={line} index={i} />;
        return <QuotationGroupRow line={line} index={i} />;
      })}
    </TableBody>
  );
};

const ChapterRow: FC<{ line: BlQuotationLine; index: number }> = ({ line, index }) => {
  const amountColIndex = tableConfig.findIndex((x) => x.label === 'Montant');
  return (
    <TableRow className="chapter-row" key={'chapter' + index} style={{ backgroundColor: 'rgba(0,100,255,0.2)' }}>
      <TableCell>
        <div style={{ marginLeft: (line.indent ?? 0) + 'rem' }}>{line.label}</div>
      </TableCell>
      <TableCell colSpan={amountColIndex - 1} />
      <TableCell className="col-show">{Utils.ThousandSpacing(line.chapterAmount ?? 0, 2)} €</TableCell>
      <TableCell colSpan={tableConfig.length - amountColIndex - 1} />
    </TableRow>
  );
};

const QuotationGroupRow: FC<{ line: BlQuotationLine; index: number }> = ({ line, index }) => {
  const style = index % 2 === 0 ? { backgroundColor: 'rgba(0,0,0,0.1)' } : {};
  return (
    <TableRow className="row-show" key={index} style={style}>
      <TableCell>
        <div style={{ marginLeft: (line.indent ?? 0) + 'rem' }}>{line.label}</div>
      </TableCell>
      <TableCell>{line.unit}</TableCell>
      <TableCell>{line.quantity}</TableCell>
      <TableCell className="col-show">{Utils.ThousandSpacing(line.price, 2)} €</TableCell>
      <TableCell className="col-show">{line.discount} %</TableCell>
      <TableCell className="col-show">{Utils.ThousandSpacing(line.totalAmount ?? 0, 2)} €</TableCell>
      <TableCell className="col-show">{(line.vatValue ?? 0) * 100} %</TableCell>
      <TableCell className="col-show">TODO</TableCell>
      <TableCell className="col-show">TODO</TableCell>
      <TableCell className="col-show">TODO</TableCell>
    </TableRow>
  );
};
