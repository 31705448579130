import React, { CSSProperties, FC, useContext, useState } from 'react';
import { GlobalContext } from '../../../context/Global.context';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { Buttons, InputUI } from '../../../component/shared';
import { CmsLink } from '../../../component/shared/Ui';
import CmsIcon from '../../../component/shared/CmsIcon';
import {
  BlPriceSchedule,
  BlPriceScheduleLineCompany,
  BlPriceScheduleLineGroup,
  BlReferenceStudyGroup,
  BlReferenceStudyGroupLine,
  BlReferenceStudyLineGroup,
} from '../../../interface/BlType';
import './priceSchedule.scss';
import { BlReferenceStudyTypeOptions } from './BlStudyCategory.pack';
import NotificationService from '../../../service/NotificationService';
import APIRoute from '../../../constant/API.constant';
import CRUD from '../../../service/CRUD.service';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { IdLabel } from '../../../interface/CommonType';
import { API_URL } from '../../../constant/API_URL';
import Tooltip from '@mui/material/Tooltip';
import { CmsColumnDndDef, CmsDndTable } from '../../../component/table/CmsDndTable';
import Utils from '../../../helper/Utils';

//#region RefStudy

interface SubRowComponentProps {
  data: BlPriceSchedule;
  handleChange: (refStudyId: number, value: any, attr?: string, table?: string) => void;
  handleDelete?: (refStudyId: number, table?: string) => void;
}

const DndGroupConfig: CmsColumnDndDef<any>[] = [
  {
    header: 'Quantité (éditable)',
    id: 'quantity',
    size: 0,
    cell: (x) => (
      <InputUI.DebouncedInput
        valueCanUpdate
        variant="standard"
        onChange={(y: any) => x.cell.setCellValue(y)}
        value={x.getValue()}
      />
    ),
  },
  {
    header: 'Désignation',
    id: 'group.label',
    size: 300,
    cell: (x) => {
      return (
        <CmsLink
          target="_blank"
          defaultStyle
          href={`/castres/billing/referencestudygroup/${x.row.original.group.id}/edit`}
          label={x.row.original.group.label}
        />
      );
    },
  },
  {
    header: 'Commentaire (éditable)',
    id: 'comment',
    size: 200,
    cell: (x) => {
      return (
        <div>
          <InputUI.DebouncedInput
            value={x.getValue()}
            variant="standard"
            valueCanUpdate
            onChange={(y: any) => x.cell.setCellValue(y)}
            multilineToggle
          />
        </div>
      );
    },
  },
  {
    header: 'Coût de revient',
    id: 'label',
    size: 0,
    cell: (x) => (
      <PriceDiv
        price={
          (x.row.original.group?.variableRateCost ?? 0) * (x.row.original.quantity ?? 0) +
          (x.row.original.group?.flatRateCost ?? 0)
        }
      />
    ),
  },
  {
    header: 'Prix de vente',
    id: 'label',
    size: 0,
    cell: (x) => (
      <PriceDiv
        price={
          (x.row.original.group?.variableRateMarkup ?? 0) * (x.row.original.quantity ?? 0) +
          (x.row.original.group?.flatRateMarkup ?? 0)
        }
      />
    ),
  },
  {
    header: 'Action',
    id: 'id',
    size: 0,
    cell: (x) => {
      return (
        <BlPriceScheduleLineGroupActionCell
          lineGroup={x.row.original}
          handleDelete={() => x.cell.lineSharedFunctionList.handleDelete?.(x.row.original.id)}
          isWarning={isPriceScheduleWarning(x.row.original, x.cell.lineSharedFunctionList.data)}
        />
      );
    },
  },
];

export const SubRowRefStudyComponent: FC<SubRowComponentProps> = ({ data, handleChange, handleDelete }) => {
  // TODO: réafficher la somme des groupes dans l'en-tête, mais il faut trouver un moyen simple
  // const payload = getAllLineGroupCalculation(0, data.blPriceScheduleLineGroup);
  if (data.blPriceScheduleLineGroup.find((x) => x.position === null)) {
    data.blPriceScheduleLineGroup = data.blPriceScheduleLineGroup.map((x, i) => ({ ...x, position: i }));
  }

  const handleOrder: any = (newData: BlPriceScheduleLineGroup[]) => {
    newData.map((x, i) => (x.position = i));
    handleChange(data.id, newData, 'reorder');
  };

  return [
    <div
      key={'subrow-body-' + data.id}
      className="cms-grid"
      style={{ gridTemplateColumns: '6rem auto', padding: '0.2rem' }}
    >
      <div className="flex-v" style={{ alignItems: 'center', justifyContent: 'center' }}>
        <h4>Référence </h4>
        <h4>d'études: </h4>
      </div>
      <CmsDndTable
        withoutPaper
        lineSharedFunctionList={{ handleDelete, handleChange, data }}
        stateHandler={{ state: data.blPriceScheduleLineGroup, setState: handleOrder }}
        columns={DndGroupConfig}
        SubRowComponent={(x) => {
          const isWarning = isPriceScheduleWarning(x.row.original, data);
          return (
            <div style={{ marginLeft: '4rem' }}>
              <SubSubRowComponent group={x.row.original.group} isWarning={isWarning} />
            </div>
          );
        }}
      />
    </div>,
  ];
};

function isPriceScheduleWarning(lineGroup: BlReferenceStudyLineGroup, data: BlPriceSchedule): boolean {
  if (lineGroup.group.lastWarningDate === null) return false;
  return new Date(lineGroup.group.lastWarningDate ?? 0) > new Date(data.newWarningAcknowledgmentDate ?? 0);
}

interface ActionCellProps {
  lineGroup: BlPriceScheduleLineGroup;
  handleDelete: any;
  isWarning: boolean;
}

const BlPriceScheduleLineGroupActionCell: FC<ActionCellProps> = ({ lineGroup, handleDelete, isWarning }) => {
  let message: any = undefined;
  if (isWarning && lineGroup.warningList && lineGroup.warningList?.length > 0)
    message = lineGroup.warningList?.map((x, i) => <p>{i + 1 + ') ' + x}</p>);
  return (
    <div className="flex-center">
      {!!message && (
        <CmsIcon icon="warning" tooltip={message} style={{ margin: '-0.2rem 0', color: 'yellow' }} textPos="left" />
      )}
      <CmsIcon style={{ margin: '-0.2rem' }} icon="delete" onClick={handleDelete} />
    </div>
  );
};

export const SubSubRowComponent: FC<{ group: BlReferenceStudyGroup; isWarning: boolean }> = ({ group, isWarning }) => {
  const { theming } = useContext(GlobalContext);
  const mainTheme = theming.get().cms.main;
  const headerStyle: CSSProperties = { backgroundColor: theming.get().cms.main.header };
  return (
    <Table className="price-ref-study-line">
      <TableHead>
        <TableRow style={headerStyle}>
          <TableCell>Désignation</TableCell>
          <TableCell style={{ width: '25rem' }}>Article</TableCell>
          <TableCell style={{ width: '5rem' }}>Type</TableCell>
          <TableCell style={{ width: '10rem' }}>Catégorie</TableCell>
          <TableCell style={{ width: '8rem' }}>Corps d'état</TableCell>
          <TableCell style={{ width: '5rem' }}>Unité</TableCell>
          <TableCell style={{ width: '5rem' }}>Quantité</TableCell>
          <TableCell style={{ width: '2.5rem' }}>Perte</TableCell>
          <TabCellWithTooltip title="P.U" label="Prix unitaire" />
          <TabCellWithTooltip title="P. Revient U." label="Prix de revient unitaire" />
          <TabCellWithTooltip title="Prix vente" label="Prix de vente" />
          <TableCell style={{ width: '2rem' }}>
            <CmsIcon icon="warning" tooltip="Avertissement de mise à jour" style={{ fontSize: '1rem' }} />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {group?.blReferenceStudyGroupLine?.map((y: BlReferenceStudyGroupLine, index) => (
          <TableRow style={{ backgroundColor: index % 2 === 1 ? mainTheme.rowOdd : mainTheme.rowEven }}>
            <TableCell>
              <div className="flex-h align-center" style={{ minHeight: '1.8rem' }}>
                {y.isFlatRate && (
                  <CmsIcon
                    icon="circle"
                    tooltip="Coûts Invariable"
                    typeColor="primary"
                    style={{ fontSize: '1rem', margin: '-0.2rem 0' }}
                  />
                )}
                <CmsLink
                  href={`/castres/billing/referencestudyline/${y.line.id}/edit`}
                  label={y.line.label ?? ''}
                  target="_blank"
                  defaultStyle
                />
              </div>
            </TableCell>
            <TableCell>
              {y.line.articleId && (
                <CmsLink
                  href={`/castres/stock/article/${y.line.articleId}/edit`}
                  label={y.line.articleLabel ?? ''}
                  target="_blank"
                  defaultStyle
                />
              )}
            </TableCell>
            <TableCell>{BlReferenceStudyTypeOptions[y.line.type ?? -1]?.label}</TableCell>
            <TableCell>{y.line.categoryLabel}</TableCell>
            <TableCell>{y.line.jobLabel}</TableCell>
            <TableCell>{y.line.unitLabel}</TableCell>
            <TableCell>
              <div style={{ textAlign: 'right' }}>{((y.quantity ?? 0) * (y.line.quantity ?? 0)).toFixed(2)}</div>
            </TableCell>
            <PercentCell percent={y.line.lossFactor} />
            <PriceCell price={(y.line.calculatedUnitPrice ?? 0) * (y.quantity ?? 0)} />
            <PriceCell price={(y.line.costPrice ?? 0) * (y.quantity ?? 0)} />
            <PriceCell price={(y.line.markupPrice ?? 0) * (y.quantity ?? 0)} />
            {(!isWarning && <TableCell />) || <WarningCell line={y} groupUpdatedAt={group.updatedAt} />}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const TabCellWithTooltip: FC<{ title: string; label: string }> = ({ title, label }) => (
  <TableCell>
    <Tooltip title={label} placement="top">
      <span>{title}</span>
    </Tooltip>
  </TableCell>
);

const WarningCell: FC<any> = ({ line }: { line: BlReferenceStudyGroupLine }) => {
  if (!line.warningList || line.warningList.length === 0) return <TableCell />;
  const tips = line.warningList.map((x, i) => <p>{i + 1 + ') ' + x}</p>);
  return (
    <TableCell key={'warningcol-' + line.lineId}>
      <CmsIcon icon="warning" style={{ color: 'yellow', margin: '-0.2rem 0' }} tooltip={tips} textPos="left" />
    </TableCell>
  );
};

const PercentCell = ({ percent }: { percent: number }) => (
  <TableCell style={{ textAlign: 'right' }}>{percent ?? 0}%</TableCell>
);

const PriceCell = ({ price }: { price: number }) => (
  <TableCell style={{ textAlign: 'right' }}>{price ? Utils.ThousandSpacing(price, 2) : 0}€</TableCell>
);

const PriceDiv = ({ price }: { price: number }) => (
  <div style={{ textAlign: 'right' }}>{price ? Utils.ThousandSpacing(price, 2) : 0}€</div>
);

export const getIndentStyle = (indent: number, offSet = 0) => ({ marginLeft: indent * 2 - 2 + offSet + 'em' });

export const getRefStudySum = (data: BlPriceSchedule, attr: string) =>
  data.blPriceScheduleLineGroup?.reduce((acc, x) => acc + ((x.group as any)[attr] ?? 0) * (x.quantity ?? 0), 0);

//#endregion

//#region PriceSchedule

export const SubRowCompanyComponent: FC<SubRowComponentProps> = ({ data, handleChange, handleDelete }) => {
  const { theming } = useContext(GlobalContext);
  const headerStyle: CSSProperties = { backgroundColor: theming.get().cms.main.header };
  const mainTheme = theming.get().cms.main;

  const isWarning = data.blPriceScheduleLineCompany?.some((x) => {
    return new Date(x.newWarningAcknowledgmentDate ?? 0) < new Date(data.lastGroupWarningDate ?? 0);
  });

  const handleAllAcknowledge = () => {
    data.blPriceScheduleLineCompany?.forEach((x) => {
      handleChange(x.id, new Date(), 'newWarningAcknowledgmentDate', 'blPriceScheduleLineCompany');
    });
  };

  let actionTitleCell = <></>;
  if (isWarning)
    actionTitleCell = (
      <div className="flex-center">
        <CmsIcon
          icon={'warning'}
          tooltip="Acquiter tous les avertissements par enseigne"
          style={{ fontSize: '1rem', color: 'orange' }}
          onClick={handleAllAcknowledge}
        />
      </div>
    );

  return (
    <div
      key={'subrow-body-' + data.id}
      className="cms-grid"
      style={{ gridTemplateColumns: '6rem auto', padding: '0.2rem' }}
    >
      <div className="flex-center">
        <h4>Enseignes</h4>
      </div>
      <div style={getIndentStyle(data.indent ?? 0, 0)}>
        <Table>
          <TableHead>
            <TableRow style={headerStyle}>
              <TableCell> Libellé </TableCell>
              <TableCell style={{ width: '15rem' }}>
                Prix (sans enseigne : {Utils.ThousandSpacing(data.price ?? 0)} €)
              </TableCell>
              <TableCell style={{ width: '7rem' }}> {actionTitleCell} </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.blPriceScheduleLineCompany?.map((x, index) => {
              return (
                <CompanyLine
                  companyLine={x}
                  index={index}
                  mainTheme={mainTheme}
                  data={data}
                  handleChange={handleChange}
                />
              );
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

interface CompanyLineProps {
  companyLine: BlPriceScheduleLineCompany;
  index: number;
  mainTheme: any;
  data: any;
  handleChange: (refStudyId: number, value: any, attr?: string, table?: string) => void;
}

const CompanyLine: FC<CompanyLineProps> = ({ companyLine, data, handleChange, index, mainTheme }) => {
  const payload = getAllLineGroupCalculation(companyLine.price ?? 0, data.blPriceScheduleLineGroup);
  const style = colorCellFromBlReferenceStudyGroupLine(companyLine.price ?? 0, payload);
  let message: string | null = null;
  if (new Date(companyLine.warningAcknowledgmentDate ?? 0) < new Date(data.lastGroupWarningDate ?? 0))
    message = 'Veuillez acquiter les avertissements en cliquant ici';
  const wasWarning = new Date(companyLine.warningAcknowledgmentDate ?? 0) < new Date(data.lastGroupWarningDate ?? 0);
  const isWarning = new Date(companyLine.newWarningAcknowledgmentDate ?? 0) < new Date(data.lastGroupWarningDate ?? 0);
  return (
    <TableRow style={{ backgroundColor: index % 2 === 1 ? mainTheme.rowOdd : mainTheme.rowEven }}>
      <TableCell>{companyLine.company.name}</TableCell>
      <TableCell style={style}>
        <InputUI.DebouncedInput
          variant="standard"
          value={companyLine.price ?? ''}
          valueCanUpdate
          onChange={(y: any) => handleChange(companyLine.id, y, 'price', 'blPriceScheduleLineCompany')}
          priceInput
        />
      </TableCell>
      <TableCell>
        <div className="flex-center" style={{ margin: '-0.2rem' }}>
          <CmsIcon
            visible={wasWarning || isWarning}
            icon={'warning'}
            tooltip={message}
            style={{ color: isWarning ? 'yellow' : 'inherit' }}
            onClick={() => {
              handleChange(
                companyLine.id,
                isWarning ? new Date() : companyLine.warningAcknowledgmentDate,
                'newWarningAcknowledgmentDate',
                'blPriceScheduleLineCompany',
              );
            }}
          />
        </div>
      </TableCell>
    </TableRow>
  );
};

const reduceGroup = (lineGroup: BlPriceScheduleLineGroup[], attr: (x: any) => number, quantity?: number) =>
  lineGroup?.reduce((acc, y) => acc + attr(y.group) * (quantity ?? y.quantity ?? 0), 0) ?? 0;

export function getAllLineGroupCalculation(value: number, lineGroup: any[]) {
  const flatRateCost = reduceGroup(lineGroup, (x) => x.flatRateCost, 1);
  const variableRateCost = reduceGroup(lineGroup, (x) => x.variableRateCost);
  const markupFlatRateCost = reduceGroup(lineGroup, (x) => x.flatRateMarkup, 1);
  const markupVariableRateCost = reduceGroup(lineGroup, (x) => x.variableRateMarkup);
  const costPrice = flatRateCost + variableRateCost;
  const markupPrice = markupFlatRateCost + markupVariableRateCost;
  const diffVariable = value - variableRateCost;
  const rentability = +(flatRateCost / diffVariable).toFixed(2);
  return { costPrice, markupPrice, flatRateCost, variableRateCost, rentability, diffVariable };
}

export function colorCellFromBlReferenceStudyGroupLine(value: number, payload: any): CSSProperties {
  if (!payload.costPrice) return {};
  let style = {} as CSSProperties;
  if (payload.diffVariable < 0) style = { backgroundColor: '#990000' };
  else if (payload.rentability > 1) style = { backgroundColor: '#FF5500' };
  else if (value > payload.costPrice && value < payload.markupPrice) style = { backgroundColor: '#AA8800' };
  else if (value > payload.markupPrice * 1.5) style = { backgroundColor: '#0099FF' };
  return style;
}

//#endregion

interface SubRowChapterPicProps {
  data: any;
  state: any[];
  setState: (x: any) => void;
  postRoute: string;
}

export const SubRowChapterPic: FC<SubRowChapterPicProps> = ({ data, state, setState, postRoute }) => {
  const [fileToUpload, setFileToUpload] = useState<File>();
  const [fileUrl, setFileUrl] = useState<string | undefined>(data.imagePath);
  const handleFileToUpload = (file: File) => {
    if (file.type === 'image/jpeg' || file.type === 'image/png') return setFileToUpload(file);
    NotificationService.error(`Le fichier ${file.name} n'est pas un jpg ou un png`);
    setFileToUpload(undefined);
  };

  const handleDeleteImage = () => {
    setFileUrl(undefined);
    const row = state.find((x) => x.id === data.id);
    if (!row) return;
    row.imagePath = undefined;
    setState([...state]);
  };

  const handleUpload = () => {
    if (!fileToUpload) return;
    CRUD.postFormData<IdLabel>(postRoute, {
      file: fileToUpload,
      id: data.id,
    }).then((x) => {
      NotificationService.success('Image téléversée avec succès');
      setFileToUpload(undefined);
      setFileUrl(x.label);
      const row = state.find((x) => x.id === data.id);
      if (!row) return;
      row.imagePath = x.label;
      setState([...state]);
    });
  };

  return (
    <div className="cms-grid" style={{ gridTemplateColumns: 'auto 18rem' }}>
      <div className="flex-center" style={{ margin: '0.5rem 0 0 0' }}>
        {(!!fileUrl && (
          <LazyLoadImage
            key={'chapter-pic-' + data.id}
            alt={'Image du chapitre ' + data.label}
            effect="opacity"
            src={`${API_URL}${APIRoute.BlPriceScheduleGroup}/ChapterPic/${fileUrl}`}
            style={{
              maxHeight: '10rem',
              borderRadius: '0.3rem',
              boxShadow: '0 0 0.5rem 0.1rem rgba(0,0,0,0.3)',
            }}
          />
        )) || <h3>Aucune Image pour l'instant</h3>}
      </div>
      <div style={{ margin: '1rem' }} className="flex-v align-center">
        <InputUI.InputFile
          style={{ marginBottom: '0.5rem' }}
          name="chapter-pic"
          id="input-file"
          onFileSelected={handleFileToUpload}
          image
        />
        <Buttons.Valid style={{ marginBottom: '0.5rem' }} disabled={!fileToUpload} onClick={handleUpload}>
          Téléverser
        </Buttons.Valid>
        {!!fileUrl && <Buttons.Cancel onClick={handleDeleteImage}>Supprimer</Buttons.Cancel>}
      </div>
    </div>
  );
};
