import React, { FC } from 'react';
import APIRoute from '../../constant/API.constant';
import AccessFilter from '../../helper/AccessFilter';
import { CmsColumnDef, CmsFrontendTable } from '../../component/table/CmsTable';
import { WkGreenfluxCdr } from '../../interface/WkType';
import CmsTableFilter from '../../component/table/helper/CmsTableFilter';
import CmsFilterCalculator from '../../component/table/helper/CmsTableFilterCalculator';
import CmsTableCell from '../../component/table/helper/CmsTableCell';
import ROLE from '../../constant/role.constant';

/**
 * Permet l'affichage de tous les CDR en base récupérés auprès de Greenflux
 * sous forme de liste
 */
const GreenfluxCdrList: FC = () => (
  <CmsFrontendTable
    title="Liste des CDR de Greenflux"
    route={APIRoute.WkGreenfluxCdr}
    columns={CdrListConfig}
    downloadable={AccessFilter([ROLE.ADMIN_GF_CDR_EXPORT]) ? 'csv' : undefined}
    navigateTo={(id: string | number) => `/castres/greenflux/cdr/${id}/show`}
    autoRefresh
    showFooter
    setFiltersInUrl
  />
);

/**
 * Liste des données affichées dans la liste par CDR reçu par le backend
 */
const CdrListConfig: CmsColumnDef<WkGreenfluxCdr>[] = [
  { header: 'id', id: 'id', hide: true },
  { header: 'Id CDR', id: 'idCdr', Filter: CmsTableFilter.Search, size: 110 },
  {
    header: 'Dernière MàJ Greenflux',
    id: 'lastUpdated',
    Filter: CmsTableFilter.Date,
    cell: CmsTableCell.Datetime,
    size: 70,
  },
  {
    header: 'Début charge',
    id: 'startDateTime',
    Filter: CmsTableFilter.Date,
    cell: CmsTableCell.Datetime,
    size: 70,
    hide: 'hiddenByDefault',
  },
  {
    header: 'Fin charge',
    id: 'stopDateTime',
    Filter: CmsTableFilter.Date,
    cell: CmsTableCell.Datetime,
    size: 70,
    hide: 'hiddenByDefault',
  },
  { header: 'Token', id: 'authId', Filter: CmsTableFilter.Select, hide: 'hiddenByDefault' },
  {
    header: "Méthode d'authentification",
    id: 'authMethod',
    Filter: CmsTableFilter.Select,
    size: 60,
    hide: 'hiddenByDefault',
  },
  {
    header: 'Coût total HT',
    id: 'totalCost',
    cell: CmsTableCell.Price,
    Filter: CmsTableFilter.Number,
    size: 40,
  },
  {
    header: 'Prix de vente HT',
    id: 'totalRetailCost',
    cell: CmsTableCell.Price,
    Filter: CmsTableFilter.Number,
    size: 40,
  },
  {
    header: 'Énergie consommée (kWh)',
    id: 'totalEnergy',
    Filter: CmsTableFilter.Number,
    size: 40,
  },
  {
    header: 'Temps de recharge (h)',
    id: 'totalTime',
    Filter: CmsTableFilter.Number,
    hide: true,
  },
  {
    header: 'Temps de recharge',
    id: 'totalTimeSeconds',
    accessorFn: (row) => (row?.totalTime ? row.totalTime * 3600 : undefined),
    cmsSortingFn: CmsFilterCalculator.sort.number,
    cell: CmsTableCell.Duration,
    size: 40,
  },
  { header: 'Forfait', id: 'retailPackageId', Filter: CmsTableFilter.Select, size: 30, hide: 'hiddenByDefault' },
  { header: 'Id client', id: 'customerExternalId', Filter: CmsTableFilter.Select, size: 70, hide: 'hiddenByDefault' },
  { header: 'Id conducteur', id: 'driverExternalId', Filter: CmsTableFilter.Select, size: 70 },
  {
    header: 'EMSP',
    id: 'emspPartyId',
    accessorFn: (row) => (row.emspPartyId ? row.emspCountryCode + '-' + row.emspPartyId : ''),
    Filter: CmsTableFilter.Select,
    size: 10,
  },
  { header: 'Id externe EMSP', id: 'emspExternalId', Filter: CmsTableFilter.Select, size: 30, hide: 'hiddenByDefault' },
  { header: 'Type chargeur', id: 'chargerType', Filter: CmsTableFilter.Select, size: 10, hide: 'hiddenByDefault' },
  { header: 'Id EVSE', id: 'evseId', Filter: CmsTableFilter.Select, size: 30, hide: 'hiddenByDefault' },
  {
    header: 'Puissance max du connecteur (kW)',
    id: 'connectorMaxPower',
    accessorFn: (row) => (row.connectorMaxPower ?? 0) / 1000,
    Filter: CmsTableFilter.Select,
    size: 30,
    hide: 'hiddenByDefault',
  },
  {
    header: 'Puissance maximum atteinte (kW)',
    id: 'maxPowerW',
    accessorFn: (row) => (row.maxPowerW ?? 0) / 1000,
    Filter: CmsTableFilter.Select,
    size: 30,
    hide: 'hiddenByDefault',
  },
  {
    header: 'Standard connecteur',
    id: 'connectorStandard',
    Filter: CmsTableFilter.Select,
    size: 30,
    hide: 'hiddenByDefault',
  },
  { header: 'Nom de la station', id: 'stationName', Filter: CmsTableFilter.Select, size: 70 },
  { header: 'Type', id: 'type', Filter: CmsTableFilter.Select, size: 40 },
  {
    header: 'Note de virement validée',
    id: 'transferedToClient',
    Filter: CmsTableFilter.Bool,
    size: 40,
    hide: 'hiddenByDefault',
  },
  {
    header: 'Référence de la note de virement',
    id: 'creditNoteRef',
    Filter: CmsTableFilter.Select,
    size: 70,
  },
];

export default GreenfluxCdrList;
