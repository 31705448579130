import React, { CSSProperties, FC, ReactNode, useContext, useEffect, useState } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import {
  Button,
  FormControlLabel,
  IconButton,
  MenuItem,
  Popover,
  RadioGroup,
  Select,
  Switch,
  TextField,
  CircularProgress,
} from '@mui/material';
import CRUD from '../../service/CRUD.service';
import './../shared/shared.scss';
import { CMSTimePicker, DatePickerVanilla } from '../shared/InputUi';
import { IdLabel, IdLabelIcon } from '../../interface/CommonType';
import Radio from '@mui/material/Radio';
import { InputUI, UI } from '../shared';
import { GlobalContext } from '../../context/Global.context';
import { ColorResult, SketchPicker } from 'react-color';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { AutoCompletor } from '../shared/AutoCompletorWithTable';
import Utils from '../../helper/Utils';
import { ADDRESS_DATA_GOUV } from 'constant/API_URL';
import CloseIcon from '@mui/icons-material/Close';
import './form.scss';

const CmsFormValidation = {
  required: 'Ce champ est requis',
  max: (length: number) => ({ value: length, message: `La valeur maximale possible est ${length}` }),
  min: (length: number) => ({ value: length, message: `La valeur minimale possible est ${length}` }),
  maxLength: (length: number) => ({ value: length, message: `La longueur maximale est de ${length} caractères` }),
  minLength: (length: number) => ({ value: length, message: `La longueur minimale est de ${length} caractères` }),
};

/**
 * Champ de formulaire conditionnel
 * @param hideIf Condition d'affichage du champ
 * @param children Contenu du champ
 */
const CmsFormInputConditional = ({ hideIf, children }: any) => {
  const { watch } = useFormContext();
  return (hideIf && hideIf(watch()) && <></>) || children;
};

interface CmsFormInputTextProps {
  id: string;
  label: string | ReactNode;
  inputRef?: any;
  errors?: any;
  onChange?: any;
  width?: string;
  placeholder?: string;
  style?: any;
  updateFieldIf?: (data: any) => { update: boolean; value: any };
  readOnly?: boolean;
  readOnlyIf?: (data: any) => boolean;
  disableIf?: (data: any) => boolean;
  requiredIf?: (data: any) => boolean;
  required?: boolean;
  disabled?: boolean;
  multiline?: boolean;
  min?: number;
  max?: number;
  hideIf?: (data: any) => boolean;
  isPhone?: true;
}

/**
 * Champ de formulaire de type texte lié à un hookForm
 * @param id id du champ
 * @param label Libellé du champ
 * @param min Longueur minimale du champ
 * @param max Longueur maximale du champ
 * @param width Largeur du champ
 * @param placeholder Placeholder du champ
 * @param required Champ requis ou non
 * @param requiredIf Est obligatoire si une condition est remplie
 * @param multiline Champ multiligne ou non
 * @param disabled Champ désactivé ou non
 * @param disableIf Condition de désactivation du champ
 * @param readOnly Champ en lecture seule ou non
 * @param readOnlyIf Condition de lecture seule du champ
 * @param style Style du champ FormField
 * @param hideIf Condition d'affichage du champ
 * @param updateFieldIf Condition de mise à jour du champ
 * @param isPhone Champ de type téléphone ou non
 */
const CmsFormInputText: FC<CmsFormInputTextProps> = ({
  id = '',
  label,
  min,
  max,
  width,
  placeholder,
  required = false,
  requiredIf,
  multiline = false,
  disabled = false,
  disableIf,
  readOnly,
  readOnlyIf,
  style,
  hideIf,
  updateFieldIf,
  isPhone,
}) => {
  const { register, formState, watch, setValue, setError, trigger } = useFormContext();
  const isRequired = requiredIf ? requiredIf(watch()) : required;
  let registerOptions: RegisterOptions = { required: isRequired ? CmsFormValidation.required : undefined };
  if (max) registerOptions.maxLength = CmsFormValidation.maxLength(max);
  if (min) registerOptions.minLength = CmsFormValidation.minLength(isPhone ? 10 : min);
  if (hideIf && hideIf(watch())) return <></>;
  if (readOnlyIf && readOnlyIf(watch())) readOnly = true;
  if (disableIf && disableIf(watch())) disabled = true;
  if (updateFieldIf) {
    const { update, value } = updateFieldIf(watch());
    if (update && value !== watch()[id]) {
      setValue(id, value);
      trigger(id);
    }
  }
  if (isPhone && watch()[id]?.length > 9 && !formState.errors[id] && !watch()[id].match(/^[0-9]{10}$/g))
    setError(id, { type: 'manual', message: 'Seuls les numéros tels que 0123456789 sont autorisés' });
  return (
    <UI.FormField>
      <UI.LabelWithError name={id} label={label} required={isRequired} formState={formState} />
      <TextField
        id={id}
        multiline={multiline}
        placeholder={placeholder}
        inputProps={{ readOnly: readOnly }}
        disabled={disabled}
        size="small"
        error={!!formState.errors[id]}
        style={{ width: width ?? '100%', ...style }}
        {...register(id, registerOptions)}
      />
    </UI.FormField>
  );
};

interface CmsFormInputNumberProps {
  id: string;
  label: string;
  inputRef?: any;
  errors?: any;
  onChange?: any;
  width?: string;
  placeholder?: string;
  style?: any;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  requiredIf?: (data: any) => boolean;
  readOnlyIf?: (data: any) => boolean;
  disableIf?: (data: any) => boolean;
  updateFieldIf?: (data: any) => { update: boolean; value: any };
  min?: number;
  max?: number;
  hideIf?: (data: any) => boolean;
  isCoordinate?: true;
}

/**
 * Champ de formulaire de type nombre lié à un hookForm
 * @param id id du champ
 * @param label Libellé du champ
 * @param min Longueur minimale du champ
 * @param max Longueur maximale du champ
 * @param width Largeur du champ
 * @param placeholder Placeholder du champ
 * @param required Champ requis ou non
 * @param disabled Champ désactivé ou non
 * @param readOnly Champ en lecture seule ou non
 * @param readOnlyIf Condition de lecture seule du champ
 * @param disableIf Condition de désactivation du champ
 * @param hideIf Condition d'affichage du champ
 * @param updateFieldIf Condition de mise à jour du champ
 * @param style Style du champ FormField
 */
const CmsFormInputNumber: FC<CmsFormInputNumberProps> = ({
  id = '',
  label,
  min,
  max,
  width,
  placeholder,
  required = false,
  disabled = false,
  readOnly = false,
  readOnlyIf,
  disableIf,
  requiredIf,
  hideIf,
  updateFieldIf,
  style,
  isCoordinate,
}) => {
  const { formState, watch, setValue, setError, control, trigger } = useFormContext();
  const isrequired = requiredIf ? requiredIf(watch()) : required;
  const registerOptions: RegisterOptions = { required: isrequired ? CmsFormValidation.required : undefined };
  if (max) registerOptions.max = CmsFormValidation.max(max);
  if (min) registerOptions.min = CmsFormValidation.min(min);
  if (hideIf && hideIf(watch())) return <></>;
  if (readOnlyIf && readOnlyIf(watch())) readOnly = true;
  if (disableIf && disableIf(watch())) disabled = true;
  if (updateFieldIf) {
    const { update, value } = updateFieldIf(watch());
    if (update && value !== watch()[id]) {
      setValue(id, value);
      trigger(id);
    }
  }
  const handleChange = (x: any, onChange: any) => {
    if (x === '0') return onChange(0);
    if (!x || x === '') return onChange(null);
    return onChange(+x);
  };
  if (isCoordinate && watch()[id] === 0 && !formState.errors[id])
    setError(id, { type: 'manual', message: 'Coordonnée 0 incorrecte' });

  return (
    <UI.FormField>
      <UI.LabelWithError label={label} name={id} required={isrequired} formState={formState} />
      <Controller
        control={control}
        name={id}
        rules={registerOptions}
        render={({ field: { onChange, value } }) => (
          <TextField
            id={id}
            value={value ?? ''}
            onChange={(x) => handleChange(x.target.value, onChange)}
            type="number"
            placeholder={placeholder}
            inputProps={{ readOnly: readOnly }}
            disabled={disabled}
            size="small"
            error={!!formState.errors[id]}
            style={{ width: width ?? '100%', ...style }}
          />
        )}
      />
    </UI.FormField>
  );
};
// {/*{...register(id, registerOptions)}*/}

interface CmsFormInputSwitchProps {
  id: string;
  label: string;
  inline?: boolean;
  inlineReverse?: boolean;
  containerStyle?: CSSProperties;
  style?: CSSProperties;
  hideIf?: (data: any) => boolean;
  disabled?: boolean;
  disableIf?: (data: any) => boolean;
  readOnly?: boolean;
  readOnlyIf?: (data: any) => boolean;
  updateFieldIf?: (data: any) => { update: boolean; value: any };
}

/**
 * Champ de type bouton radio on/off lié à un hookForm
 * @param control Gestionnaire de formulaire (géré par le hookForm)
 * @param inline Défini si le libellé est au-dessus ou à gauche du champ (défaut : false)
 * @param inlineReverse Défini si le libellé est à droite ou à gauche du champ (défaut : false)
 * @param label Libellé du champ
 * @param id id du champ
 * @param style Style du champ FormField
 */
const CmsFormInputSwitch: FC<CmsFormInputSwitchProps> = ({
  id,
  label,
  inline,
  inlineReverse,
  containerStyle,
  style,
  hideIf,
  disabled = false,
  disableIf,
  readOnly = false,
  readOnlyIf,
  updateFieldIf,
}) => {
  const { control, formState, watch, setValue, trigger } = useFormContext();
  let inlineStyle: CSSProperties = { display: 'flex', flexDirection: 'row', alignItems: 'center' };
  inlineStyle = inlineReverse ? { ...inlineStyle, justifyContent: 'start', flexDirection: 'row-reverse' } : inlineStyle;
  if (!inline && !inlineReverse) inlineStyle = {};
  if (hideIf && hideIf(watch())) return <></>;
  if (readOnlyIf && readOnlyIf(watch())) readOnly = true;
  if (disableIf && disableIf(watch())) disabled = true;
  if (updateFieldIf) {
    const { update, value } = updateFieldIf(watch());
    if (update && value !== watch()[id]) {
      setValue(id, value);
      trigger(id);
    }
  }
  const props = { style, color: 'primary' as any, readOnly, disabled };
  return (
    <UI.FormField className="switch-form" style={{ ...inlineStyle, ...containerStyle }}>
      <UI.LabelWithError name={id} required={false} label={label} formState={formState} />
      <Controller
        control={control}
        name={id}
        render={({ field: { onChange, value } }) => (
          <Switch checked={value ?? false} onChange={() => onChange(!value)} {...props} />
        )}
      />
    </UI.FormField>
  );
};

interface CmsFormSelectProps {
  id: string;
  label: string;
  onChange?: (data: any) => void;
  options: any[] | string;
  disabled?: boolean;
  disableIf?: (data: any) => boolean;
  readOnly?: boolean;
  readOnlyIf?: (data: any) => boolean;
  requiredIf?: (data: any) => boolean;
  inputStyle?: CSSProperties;
  multiple?: boolean;
  optionLabel?: string | ((data: any) => string | undefined);
  // Si vous voulez tout l'objet, mettez 'all'
  optionValue?: string;
  required?: boolean;
  rules?: RegisterOptions;
  style?: CSSProperties;
  width?: string;
  // Retourne tout l'objet dans le onChange
  returnObject?: boolean;
  hideIf?: (data: any) => boolean;
  updateFieldIf?: (data: any, optionList: any[]) => { update: boolean; value: any };
  filterByActive?: boolean;
}

/**
 * Gestion des libellés personnalisés
 * @param optionList Liste des options
 * @param optionLabel Libellé de l'option
 */
function handleCustomLabel(optionList: any[], optionLabel: string | ((data: any) => string | undefined)): any[] {
  if (typeof optionLabel === 'string') return optionList;
  return optionList.filter((x) => !!optionLabel(x)).map((x) => ({ ...x, customLabel: optionLabel(x) }));
}

/**
 * Champ de formulaire de type select lié à un hookForm
 * @param id id du champ
 * @param label Libellé du champ
 * @param disabled Champ désactivé ou non
 * @param disableIf Condition de désactivation du champ
 * @param readOnly Champ en lecture seule ou non
 * @param readOnlyIf Condition de lecture seule du champ
 * @param requiredIf Condition de champ requis
 * @param onChange Fonction de changement de valeur
 * @param inputStyle Style du champ FormField
 * @param multiple Champ multiselect ou non
 * @param optionLabel Libellé de l'option
 * @param optionValue Valeur de l'option
 * @param options Liste des options
 * @param required Champ requis ou non
 * @param rules Règles de validation du champ
 * @param style Style du champ FormField
 * @param width Largeur du champ
 * @param hideIf Condition d'affichage du champ
 * @param updateFieldIf Condition de mise à jour du champ
 */
const CmsFormSelect: FC<CmsFormSelectProps> = ({
  id,
  label,
  disabled = false,
  disableIf,
  readOnly = false,
  readOnlyIf,
  requiredIf,
  onChange,
  inputStyle,
  multiple = false,
  optionLabel = 'label',
  optionValue,
  options,
  required = false,
  rules,
  style,
  width,
  hideIf,
  updateFieldIf,
  returnObject,
  filterByActive = false,
}) => {
  const { control, formState, watch, setValue, trigger } = useFormContext();
  const [firstRender, setFirstRender] = React.useState(true);
  const [optionList, setOptionList] = React.useState<any[]>([]);
  useEffect(() => {
    if (Array.isArray(options)) setOptionList(handleCustomLabel(options, optionLabel));
  }, [optionLabel, options]);

  useEffect(() => {
    if (!filterByActive || optionList.length === 0) return;

    const newOptionList = optionList.filter(
      (x) => x.active || x.id === watch(id) || (multiple && watch(id)?.some((c: number) => c === x.id)),
    );

    // Vérifiez si la liste est différente avant de mettre à jour l'état
    setOptionList((prev) => (JSON.stringify(prev) === JSON.stringify(newOptionList) ? prev : newOptionList));
  }, [optionList, id, multiple, watch, filterByActive]);
  if (hideIf && hideIf(watch())) return <></>;
  if (readOnlyIf && readOnlyIf(watch())) readOnly = true;
  if (disableIf && disableIf(watch())) disabled = true;
  if (updateFieldIf) {
    const { update, value } = updateFieldIf(watch(), optionList);
    if (update && value !== watch()[id]) {
      setValue(id, value);
      trigger(id);
    }
  }

  if (firstRender) {
    setFirstRender(false);
    if (!Array.isArray(options))
      CRUD.getList<any>(options).then((x) => setOptionList(handleCustomLabel(x, optionLabel)));
  }
  const isRequired = requiredIf ? requiredIf(watch()) : required;
  return (
    <Controller
      name={id}
      rules={{ required: isRequired ? CmsFormValidation.required : undefined, ...rules }}
      control={control}
      render={({ field, ...props }) => {
        if ((field.value === undefined || field.value === null) && multiple) field.value = [];
        return (
          <AutoCompletor
            error={!!formState.errors[id]}
            options={optionList}
            readOnly={readOnly}
            optionLabel={typeof optionLabel === 'string' ? optionLabel : 'customLabel'}
            optionValue={optionValue}
            required={isRequired}
            name={id}
            returnObject={returnObject}
            label={label}
            width={width}
            disabled={disabled}
            multiple={multiple}
            value={field.value}
            style={style}
            inputStyle={inputStyle}
            onChange={(x: any) => {
              onChange && onChange(x);
              if (!returnObject) return field.onChange(x ?? null);
              if (!multiple) field.onChange(x?.[optionValue ?? 'id'] ?? null);
              else field.onChange(x?.map((c: any) => c[optionValue ?? 'id']) ?? []);
            }}
            control={control}
            extendsProps={props}
            formState={formState}
          />
        );
      }}
    />
  );
};

interface CmsFormInputDateProps {
  id: string;
  label: string;
  required?: boolean;
  style?: CSSProperties;
  disabled?: boolean;
  disabledIf?: (data: any) => boolean;
  readOnly?: boolean;
  readOnlyIf?: (data: any) => boolean;
  format?: string;
  requiredIf?: (data: any) => boolean;
  updateFieldIf?: (data: any) => { update: boolean; value: any };
  hideIf?: (data: any) => boolean;
  dateOnlyFormat?: boolean;
  onDateChange?: (date: Date | null) => void;
  rules?: RegisterOptions;
}

/**
 * Champ de formulaire de type date lié à un hookForm
 * @param id id du champ
 * @param label Libellé du champ
 * @param style Style du champ FormField
 * @param required Champ requis ou non
 * @param disabled Champ désactivé ou non
 * @param readOnly Champ en lecture seule ou non
 * @param requiredIf Condition de champ requis
 * @param disabledIf Condition de désactivation du champ
 * @param readOnlyIf Condition de lecture seule du champ
 * @param format Format de la date (défaut : dd/MM/yyyy)
 * @param updateFieldIf Condition de mise à jour du champ
 * @param hideIf Condition d'affichage du champ
 * @param onDateChange Fonction appelée lors du changement de date
 * @param dateOnlyFormat Défini si le champ doit être de type date uniquement
 */
const CmsFormInputDate: FC<CmsFormInputDateProps> = ({
  id,
  label,
  style,
  required = false,
  disabled = false,
  readOnly = false,
  requiredIf,
  disabledIf,
  readOnlyIf,
  format = 'dd/MM/yyyy',
  updateFieldIf,
  hideIf,
  onDateChange,
  dateOnlyFormat = false,
}) => {
  const { formState, setValue, control, watch, trigger } = useFormContext();
  if (hideIf && hideIf(watch())) return <></>;
  if (readOnlyIf && readOnlyIf(watch())) readOnly = true;
  if (disabledIf && disabledIf(watch())) disabled = true;
  if (updateFieldIf) {
    const { update, value } = updateFieldIf(watch());
    if (update && value !== watch()[id]) {
      setValue(id, value);
      trigger(id);
    }
  }
  const isRequired = requiredIf ? requiredIf(watch()) : required;
  return (
    <Controller
      name={id}
      rules={{ required: isRequired ? CmsFormValidation.required : undefined }}
      control={control}
      render={({ field: { onChange, value } }) => {
        if (value === undefined) value = null;
        if (typeof value === 'string') value = new Date(value);
        const handleChange = (date: Date | null) => {
          onDateChange && onDateChange(date);
          onChange(!date ? null : dateOnlyFormat ? Utils.Date.dateOnlyFormat(date) : date);
        };
        return (
          <UI.FormField style={style}>
            <UI.LabelWithError name={id} required={isRequired} label={label} formState={formState} />
            <DatePickerVanilla
              error={!!formState.errors[id]}
              value={value}
              onChange={handleChange}
              disabled={disabled}
              readOnly={readOnly}
              format={format}
            />
          </UI.FormField>
        );
      }}
    />
  );
};

const CmsFormInputDateTime: FC<CmsFormInputDateProps> = ({
  id,
  label,
  style,
  required = false,
  disabled = false,
  readOnly = false,
  requiredIf,
  disabledIf,
  readOnlyIf,
  format = 'dd/MM/yyyy',
  updateFieldIf,
  hideIf,
  onDateChange,
  rules,
}) => {
  const { formState, setValue, control, watch, trigger } = useFormContext();
  const [dateTime, setDateTime] = useState<Date | null>(null);

  if (hideIf && hideIf(watch())) return null;
  if (readOnlyIf && readOnlyIf(watch())) readOnly = true;
  if (disabledIf && disabledIf(watch())) disabled = true;

  if (updateFieldIf) {
    const { update, value } = updateFieldIf(watch());
    if (update && value !== watch()[id]) {
      setValue(id, value);
      trigger(id);
    }
  }

  const isRequired = requiredIf ? requiredIf(watch()) : required;

  return (
    <Controller
      name={id}
      control={control}
      rules={{ required: isRequired ? CmsFormValidation.required : undefined, ...rules }}
      render={({ field: { onChange, value } }) => {
        if (value === undefined) value = null;
        if (typeof value === 'string') {
          value = new Date(value);
          onChange(value);
          setDateTime(value);
        }
        const handleDateChange = (date: Date | null) => {
          date?.setHours(dateTime?.getHours() ?? 0, dateTime?.getMinutes() ?? 0);
          setDateTime(date);
          onChange(date);
          onDateChange?.(date);
        };

        const handleTimeChange = (time: Date | null) => {
          time!.setFullYear(dateTime!.getFullYear(), dateTime!.getMonth(), dateTime!.getDate());
          setDateTime(time);
          onChange(time);
          onDateChange?.(time);
        };

        return (
          <UI.FormField style={style}>
            <UI.Label name={id} required={isRequired} label={label} style={{ width: '200px' }} />
            <div className="date-and-time-picker">
              <DatePickerVanilla
                error={!!formState.errors[id]}
                value={dateTime}
                onChange={handleDateChange}
                disabled={disabled}
                readOnly={readOnly}
                format={format}
              />
              <CMSTimePicker
                value={dateTime}
                onChange={handleTimeChange}
                readOnly={!dateTime}
                error={!!formState.errors[id]}
              />
            </div>
            <UI.LabelWithError name={id} formState={formState} />
          </UI.FormField>
        );
      }}
    />
  );
};

interface CmsFormInputRadioProps {
  id: string;
  label: string;
  disabled?: boolean;
  disabledIf?: (data: any) => boolean;
  options: IdLabel[];
  style?: CSSProperties;
  vertical?: boolean;
  hideIf?: (data: any) => boolean;
  updateFieldIf?: (data: any) => { update: boolean; value: any };
}

/**
 * Champ de formulaire de type radio lié à un hookForm
 * @param label Libellé du champ
 * @param id id du champ
 * @param options Liste des options
 * @param style Style du champ FormField
 * @param vertical Défini si les boutons radio sont affichés verticalement ou non
 * @param hideIf Condition d'affichage du champ
 * @param disabled Champ désactivé ou non
 * @param disabledIf Condition de désactivation du champ
 * @param updateFieldIf Condition de mise à jour du champ
 */
const CmsFormInputRadio: FC<CmsFormInputRadioProps> = ({
  label,
  id,
  options,
  style,
  vertical = false,
  hideIf,
  disabled,
  disabledIf,
  updateFieldIf,
}) => {
  const { formState, control, watch, setValue, trigger } = useFormContext();
  if (hideIf && hideIf(watch())) return <></>;
  if (disabledIf && disabledIf(watch())) disabled = true;
  if (updateFieldIf) {
    const { update, value } = updateFieldIf(watch());
    if (update && value !== watch()[id]) {
      setValue(id, value);
      trigger(id);
    }
  }
  let groupRadioStyle: CSSProperties = { display: 'flex', flexDirection: 'row' };
  if (vertical) groupRadioStyle = {};
  const retype = (x: any) => (isNaN(x.target.value) ? x.target.value : +x.target.value);
  return (
    <UI.FormField style={style}>
      <UI.LabelWithError label={label} name={id} required={false} formState={formState} />
      <Controller
        control={control}
        name={id}
        render={({ field: { onChange, value } }) => {
          return (
            <RadioGroup onChange={(x) => onChange(retype(x))} style={groupRadioStyle} value={value ?? null}>
              {options &&
                options.map((option: IdLabel, i: number) => (
                  <FormControlLabel
                    key={i}
                    control={<Radio />}
                    label={option.label}
                    value={option.id}
                    disabled={disabled}
                  />
                ))}
            </RadioGroup>
          );
        }}
      />
    </UI.FormField>
  );
};

interface ControlledColorProps {
  id: string;
  label: string;
  withoutSharp?: boolean;
  isNullable?: boolean;
  placeholder?: string;
  required?: boolean;
  requiredIf?: (data: any) => boolean;
}

const anchorOrigin: any = { vertical: 'bottom', horizontal: 'center' };
const transformOrigin: any = { vertical: 'top', horizontal: 'center' };

/**
 * Champ de formulaire de type color picker lié à un hookForm
 * @param id id du champ
 * @param label Libellé du champ
 * @param withoutSharp Défini si le champ doit être renvoyer sans le #
 * @param isNullable Défini si le champ peut avoir la valeur null ou non
 * @param placeholder Placeholder du champ
 * @param required Champ requis ou non
 */
const ColorPickerControlled: FC<ControlledColorProps> = ({
  id,
  label,
  withoutSharp = false,
  isNullable = true,
  placeholder = 'Veuillez choisir une couleur',
  required,
  requiredIf,
}) => {
  const { formState, control } = useFormContext();
  const { theming } = useContext(GlobalContext);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  const idHtml = open ? 'simple-popover' : undefined;
  const isRequired = requiredIf ? requiredIf(formState) : required;
  return (
    <UI.FormField>
      <UI.LabelWithError name={id} required={isRequired} label={label} formState={formState} />
      <Controller
        defaultValue={null}
        name={id}
        rules={{ required: isRequired ? CmsFormValidation.required : undefined }}
        control={control}
        render={({ field }) => {
          const handleChange = (val: ColorResult) => {
            field.onChange(!val?.hex ? null : withoutSharp ? val.hex.split('#')[1] : val.hex);
          };
          const handleValue = (value: string): string => {
            if (!value) return theming.get().palette.text.primary;
            return withoutSharp ? '#' + value : value;
          };
          const buttonProps: any = { variant: 'contained', style: { backgroundColor: handleValue(field.value) } };
          const popProps: any = { open, anchorEl, onClose: handleClose, transformOrigin, anchorOrigin };
          return (
            <>
              <Button aria-describedby={idHtml} onClick={handleClick} {...buttonProps}>
                {field.value ? label : placeholder}
              </Button>
              {isNullable && field.value && (
                <IconButton onClick={() => field.onChange(null)} size="small" style={{ marginLeft: '12px' }}>
                  <DeleteOutlinedIcon />
                </IconButton>
              )}
              <Popover id={idHtml} {...popProps}>
                <SketchPicker color={handleValue(field.value)} onChange={handleChange} />
              </Popover>
            </>
          );
        }}
      />
    </UI.FormField>
  );
};

interface FormSelectProps {
  // Nom du champ
  id: string;
  // Libellé du champ
  label: string;
  // Liste des options proposées format par défaut : [{id: 1, label: 'test', icon: <Icon />}], Icône facultative
  options: Array<IdLabelIcon | IdLabel> | string;
  // Style du champ FormField
  style?: React.CSSProperties;
  // Style du champ Select
  itemStyle?: React.CSSProperties;
  // Défini si le champ est désactivé ou non (grisé)
  disabled?: boolean;
  // Défini si le champ peut avoir la valeur null ou non
  nullable?: boolean;
  // Champ requis ou non
  required?: boolean;
  requiredIf?: (data: any) => boolean;
  optionLabel?: string | ((data: any) => string | undefined);
  optionValue?: string;
}

/**
 * Champ de formulaire de type select lié à un hookForm
 * @param disabled Champ désactivé ou non
 * @param itemStyle Style des options du select
 * @param label Libellé du champ
 * @param id id du champ
 * @param nullable Défini si le champ peut avoir la valeur null ou non
 * @param options Liste des options
 * @param required Champ requis ou non
 * @param style Style du champ FormField
 * @param optionLabel Libellé de l'option
 * @param optionValue Valeur de l'option
 */
const CmsFormSimpleSelect: FC<FormSelectProps> = ({
  disabled = false,
  itemStyle,
  label,
  id,
  nullable = false,
  options,
  required,
  style,
  requiredIf,
  optionLabel = 'label',
  optionValue = 'id',
}) => {
  const { formState, control } = useFormContext();
  const [firstRender, setFirstRender] = React.useState(true);
  const [optionList, setOptionList] = React.useState<any[]>([]);

  if (firstRender) {
    setFirstRender(false);
    if (Array.isArray(options)) setOptionList(handleCustomLabel(options, optionLabel));
    else CRUD.getList<any>(options).then((x) => setOptionList(handleCustomLabel(x, optionLabel)));
  }
  const isRequired = requiredIf ? requiredIf(formState) : required;
  return (
    <UI.FormField style={style}>
      <UI.LabelWithError name={id} label={label} required={isRequired} formState={formState} />
      <Controller
        name={id}
        control={control}
        rules={{ required: isRequired ? CmsFormValidation.required : undefined }}
        render={({ field }) => {
          return (
            <Select
              name={id}
              disabled={disabled || !options}
              inputProps={{ name: id }}
              labelId="simple-select"
              onChange={field.onChange}
              style={{ width: '100%' }}
              value={field.value ?? ''}
              size="small"
            >
              {nullable && (
                <MenuItem value={undefined} style={itemStyle}>
                  Aucun
                </MenuItem>
              )}
              {optionList &&
                optionList.map((opt, i) => (
                  <MenuItem key={i} value={opt[optionValue]} style={itemStyle}>
                    {'icon' in opt && opt.icon}
                    {opt.label}
                  </MenuItem>
                ))}
            </Select>
          );
        }}
      />
    </UI.FormField>
  );
};

interface CmsFormInputFileProps {
  id: string;
  label: string;
  // updateFieldIf?: (data: any) => { update: boolean; value: any };
  readOnlyIf?: (data: any) => boolean;
  disableIf?: (data: any) => boolean;
  required?: boolean;
  requiredIf?: (data: any) => boolean;
  disabled?: boolean;
  hideIf?: (data: any) => boolean;
  // Le champ est-il un champ d'upload d'image ?
  image?: boolean;
  // Le champ est-il un champ d'upload de pdf ?
  pdfOnly?: boolean;
  // Le champ est-il un champ d'upload d'image jpg ?
  jpgOnly?: boolean;
  multiple?: boolean;
}

/**
 * Champ de type fichier lié à un hookForm
 * @param id id du champ
 * @param required champ requis ou non
 * @param label libellé du champ
 * @param hideIf champ caché ou non
 * @param disabled champ désactivé ou non
 * @param disableIf champ désactivé ou non en fonction de la valeur d'un autre champ
 * @param readOnlyIf champ en lecture seule ou non en fonction de la valeur d'un autre champ
 * @param image le champ est-il un champ d'upload d'image ?
 * @param pdfOnly le champ est-il un champ d'upload de pdf ?
 * @param jpgOnly le champ est-il un champ d'upload d'image jpg ?
 * @param requiredIf le champ devient obligatoire selon la condition
 * @param multiple on peut selectionner plusieurs document d'une fois
 */
const CmsFormInputFile: FC<CmsFormInputFileProps> = ({
  id,
  required,
  label,
  hideIf,
  disabled,
  disableIf,
  readOnlyIf,
  image,
  pdfOnly,
  jpgOnly,
  requiredIf,
  multiple = false,
}) => {
  const { formState, control, watch } = useFormContext();
  if (hideIf && hideIf(watch())) return <></>;
  if (disableIf && disableIf(watch())) disabled = true;
  if (readOnlyIf && readOnlyIf(watch())) disabled = true;
  const isRequired = requiredIf ? requiredIf(watch()) : required;
  return (
    <UI.FormField>
      <UI.LabelWithError name={id} required={isRequired} label={label} formState={formState} />
      <Controller
        name={id}
        rules={{ required: isRequired ? CmsFormValidation.required : undefined }}
        control={control}
        render={({ field }) => {
          const fileProps: any = { disabled, image, pdfOnly, jpgOnly };
          return <InputUI.InputFile {...fileProps} onFileSelected={field.onChange} multiple={multiple} />;
        }}
      />
    </UI.FormField>
  );
};

interface CmsFormInputSuggestProps {
  id: string;
  label: string | ReactNode;
  route: string;
  readOnlyIf?: (data: any) => boolean;
  requiredIf?: (data: any) => boolean;
  disableIf?: (data: any) => boolean;
  required?: boolean;
  disabled?: boolean;
  hideIf?: (data: any) => boolean;
  optionValue?: string;
  optionLabel?: string;
  defaultValueHolder?: string;
  onValueSelected?: (data: any) => void;
}

/**
 * Champ de type suggest lié à un hookForm
 * @param id id du champ
 * @param label libellé du champ
 * @param route route de l'api à appeler
 * @param required champ requis ou non
 * @param readOnlyIf champ en lecture seule ou non en fonction de la valeur d'un autre champ
 * @param disableIf champ désactivé ou non en fonction de la valeur d'un autre champ
 * @param disabled champ désactivé ou non
 * @param hideIf champ caché ou non en fonction de la valeur d'un autre champ
 * @param optionValue définit la valeur de l'option
 * @param optionLabel définit le libellé de l'option
 * @param defaultValueHolder définit la valeur par défaut du champ
 */
const CmsFormInputSuggest: FC<CmsFormInputSuggestProps> = ({
  id,
  label,
  route,
  required,
  readOnlyIf,
  disableIf,
  disabled,
  hideIf,
  requiredIf,
  optionValue = 'id',
  optionLabel = 'label',
  defaultValueHolder,
  onValueSelected,
}) => {
  const { formState, control, watch } = useFormContext();
  if (hideIf && hideIf(watch())) return <></>;
  if (disableIf && disableIf(watch())) disabled = true;
  if (readOnlyIf && readOnlyIf(watch())) disabled = true;
  const isRequired = requiredIf ? requiredIf(watch()) : required;
  return (
    <UI.FormField>
      <UI.LabelWithError name={id} required={isRequired} label={label} formState={formState} />
      <Controller
        name={id}
        rules={{ required: isRequired ? CmsFormValidation.required : undefined }}
        control={control}
        render={({ field }) => {
          return (
            <InputUI.CustomSuggest
              disabled={disabled}
              name={id}
              initialValue={defaultValueHolder}
              onValueSelected={(x: any) => {
                onValueSelected && onValueSelected(x);
                field.onChange(x ? x[optionValue] : null);
              }}
              getSuggestionService={(search: string) => {
                return !search ? Promise.resolve([]) : CRUD.getSuggestedList(route, { search });
              }}
              showAttribute={optionLabel}
            />
          );
        }}
      />
    </UI.FormField>
  );
};

interface CmsFormInputAddressProps {
  id: string;
  label: string;
  max?: number;
  required?: boolean;
  requiredIf?: (data: any) => boolean;
  width?: string;
  placeholder?: string;
  style?: any;
  readOnly?: boolean;
  readOnlyIf?: (data: any) => boolean;
}

/**
 * Champ de type adresse avec suggestion
 * @param id id du champ
 * @param label libellé du champ
 * @param required champ requis ou non
 * @param max nombre maximum de caractères
 * @param requiredIf Est obligatoire si une condition est remplie
 * @param width Largeur du champ
 * @param placeholder Placeholder du champ
 * @param style Style du champ FormField
 * @param readOnly Champ en lecture seule ou non
 * @param readOnlyIf Condition de lecture seule du champ
 */
const CmsFormInputAddress: FC<CmsFormInputAddressProps> = ({
  id,
  label,
  max,
  required,
  requiredIf,
  width,
  placeholder,
  style,
  readOnly,
  readOnlyIf,
}) => {
  const { theming } = useContext(GlobalContext);
  const { setValue, formState, watch, register } = useFormContext();
  const [query, setQuery] = React.useState<string>('');
  const [debouncedQuery, setDebouncedQuery] = React.useState<string>('');
  const [suggestions, setSuggestions] = React.useState<any[]>([]);
  const [closeSuggestions, setCloseSuggestions] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const API_URL = ADDRESS_DATA_GOUV;
  const isRequired = requiredIf ? requiredIf(watch()) : required;
  const placeholderText: string = !!placeholder && placeholder?.length > 0 ? placeholder : 'entrer une adresse';
  let registerOptions: RegisterOptions = { required: isRequired ? CmsFormValidation.required : undefined };
  if (max) registerOptions.maxLength = CmsFormValidation.maxLength(max);
  if (readOnlyIf && readOnlyIf(watch())) readOnly = true;

  //Mise à jour du debouncedQuery après un délai
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedQuery(query);
    }, 500); // Délai de 500ms

    return () => clearTimeout(timer);
  }, [query]);

  //Gestion de la recherche d'adresse
  //Appel API déclenché uniquement au changement de 'debouncedQuery'
  useEffect(() => {
    if (debouncedQuery?.length < 6) {
      setSuggestions([]);
      return;
    }

    const fetchSuggestions = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_URL}?q=${encodeURIComponent(debouncedQuery)}`);
        const data = await response.json();
        setCloseSuggestions(false);
        setSuggestions(data.features);
      } catch (error) {
        console.error('Erreur lors de la récupération des adresses', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSuggestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery]);
  //Gestion de la sélection d'une adresse
  const handleSelect = (suggestion: any) => {
    const { properties, geometry } = suggestion;
    setValue(id, properties.name);
    setValue('postcode', properties.postcode);
    setValue('city', properties.city);
    setValue('latitude', geometry.coordinates[1]);
    setValue('longitude', geometry.coordinates[0]);
    setSuggestions([]);
  };
  // Gestion de la fermeture des suggestions
  const handleClose = () => {
    setCloseSuggestions(true); // Masque la div contenant les suggestions
  };

  return (
    <UI.FormField>
      <UI.LabelWithError name={id} required={isRequired} label={label} formState={formState} />
      <TextField
        id={id}
        multiline={true}
        placeholder={placeholderText}
        size="small"
        error={!!formState.errors[id]}
        style={{ width: width ?? '100%', ...style }}
        InputProps={{
          endAdornment: loading && <CircularProgress size={20} />,
          readOnly: readOnly,
        }}
        {...register(id, {
          ...registerOptions,
          onChange: (e) => {
            setQuery(e.target.value);
          },
        })}
      />
      {suggestions && suggestions.length > 0 && !closeSuggestions && (
        <div
          style={{
            maxHeight: '150px',
            overflowY: 'scroll',
            border: '2px solid #ddd',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton aria-label="close" size="small" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          {suggestions.map((suggestion, index) => (
            <MenuItem
              key={index}
              onClick={() => handleSelect(suggestion)}
              sx={{ backgroundColor: theming.get().cms.main.paper }}
            >
              {suggestion.properties.label}
            </MenuItem>
          ))}
        </div>
      )}
    </UI.FormField>
  );
};

export const CmsFormInput = {
  Conditional: CmsFormInputConditional,
  Number: CmsFormInputNumber,
  Select: CmsFormSelect,
  SimpleSelect: CmsFormSimpleSelect,
  Radio: CmsFormInputRadio,
  Date: CmsFormInputDate,
  Text: CmsFormInputText,
  Switch: CmsFormInputSwitch,
  Color: ColorPickerControlled,
  File: CmsFormInputFile,
  Suggest: CmsFormInputSuggest,
  DateAndTime: CmsFormInputDateTime,
  Address: CmsFormInputAddress,
};
