import React, { FC, useEffect } from 'react';
import APIRoute from '../../constant/API.constant';
import { Buttons, UI } from '../../component/shared';
import { CmsPaper } from '../../component/shared/Ui';
import { IdLabel } from '../../interface/CommonType';
import CRUD from '../../service/CRUD.service';
import NotificationService from '../../service/NotificationService';
import '../user/user.scss';
import { CmsForm } from '../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import LoadingScreen from '../../component/LoadingScreen';

/**
 * Permet d'afficher et d'interagir avec les configurations externes stockées en BDD
 */
export const WkConfigurationSettings: FC = () => {
  const [defaultValue, setDefaultValue] = React.useState<Settings>();

  useEffect(() => {
    CRUD.getCustomObject<any>(APIRoute.Other + '/settings').then(setDefaultValue);
  }, []);

  const submit = (data: Settings) => {
    return CRUD.post<Settings>(APIRoute.Other + '/settings', data).then((result) => {
      if (Array.isArray(result)) NotificationService.error('Échec de mise à jour');
      else NotificationService.success('Paramètres mis à jour');
    });
  };

  if (!defaultValue) return <LoadingScreen />;
  return (
    <CmsForm currentUrl="" route={APIRoute.WkGreenflux + '/settings'} onSubmit={submit} defaultValues={defaultValue}>
      <CmsPaper title="Paramètres de Greenflux" className="account-form" style={{ maxWidth: '600px' }}>
        <CmsFormInput.Text id="greenfluxUrl" label="URL de l'API Greenflux" max={250} />
        <CmsFormInput.Text id="greenfluxApiCdr" label="Token d'authentification de l'API Greenflux" max={250} />
        <CmsFormInput.Text id="greenfluxTpeTokenString" label="Liste des tokens identifiants des Tpe C4Energies" />
        <UI.Divider />
      </CmsPaper>
      <CmsPaper title="Paramètres Total" className="account-form" style={{ maxWidth: '600px' }}>
        <CmsFormInput.Text id="totalDaisyUrl" label="Endpoint de l'API Daisy en prod" max={250} />
        <CmsFormInput.Text id="totalDaisyUsername" label="Nom d'utilisateur pour l'API Daisy" max={250} />
        <UI.Divider />
      </CmsPaper>
      <CmsPaper title="Paramètres Azure" className="account-form" style={{ maxWidth: '600px' }}>
        <CmsFormInput.Text id="azureClientId" label="Id Client (Application: CMS)" max={250} />
        <CmsFormInput.Text id="azureTenantId" label="Id Tenant (Organisation : Castres Equipement)" max={250} />
        <UI.Divider />
      </CmsPaper>
      <CmsPaper title="Paramètres AS24" className="account-form" style={{ maxWidth: '600px' }}>
        <CmsFormInput.Text id="aS24SftpLogin" label="Login pour le SFTP d'AS24 en prod" max={250} />
        <CmsFormInput.Text
          id="aS24FetchEmailAddress"
          label="Adresse mail qui reçoit les XML de demande d'intervention"
          max={250}
        />
        <CmsFormInput.Text id="aS24SftpHost" label="Adresse du serveur SFTP d'AS24" max={250} />
        <UI.Divider />
      </CmsPaper>
      <CmsPaper title="Paramètres Geocoding" className="account-form" style={{ maxWidth: '600px' }}>
        <CmsFormInput.Text id="geocodingUrl" label="URL de l'API" max={250} />
        <CmsFormInput.Text id="geocodingKey" label="Clé API" max={250} />
        <UI.Divider />
      </CmsPaper>
      <CmsPaper title="Paramètres UrgentTech" className="account-form" style={{ maxWidth: '600px' }}>
        <CmsFormInput.Text id="urgentTechUrl" label="URL de l'API" max={250} />
        <CmsFormInput.Text id="urgentTechApiKeyCER" label="Clé CER" max={250} />
        <CmsFormInput.Text id="urgentTechApiSecretCER" label="Secret CER" max={250} />
        <CmsFormInput.Text id="urgentTechApiKeyENI" label="Clé ENI" max={250} />
        <CmsFormInput.Text id="urgentTechApiSecretENI" label="Secret ENI" max={250} />
        <CmsFormInput.Text id="urgentTechApiKeyQ8" label="Clé Q8" max={250} />
        <CmsFormInput.Text id="urgentTechApiSecretQ8" label="Secret Q8" max={250} />
        <UI.Divider />
      </CmsPaper>
      <CmsPaper title="Paramètres Mailing" className="account-form" style={{ maxWidth: '600px' }}>
        <CmsFormInput.Switch
          id="mailingSendBySmtp"
          label="Envoi de mail via SMTP (true) ou via Microsoft Graph (false)"
        />
        <CmsFormInput.Text
          id="mailingDevEmail"
          label="Liste des mails internes autorisés hors production (sans @castres-equipement.com)"
          max={250}
        />
        <CmsFormInput.Text
          id="mailingExtDevEmail"
          label="Liste des mails externes autorisés hors production"
          max={250}
        />
        <CmsFormInput.Text
          id="mailingFallbackDevEmail"
          label="Adresse de Fallback des mails hors production"
          required
          max={250}
        />
        <CmsFormInput.Text id="mailingProfessionalDomain" label="Domaines des adresses internes" required max={250} />
        <CmsFormInput.Text
          id="mailingFallbackSupportEmail"
          label="Adresse mail du support informatique"
          required
          max={250}
        />
        <CmsFormInput.Text
          id="mailingFallbackRHEmail"
          label="	Adresse mail pour contacter le service RH"
          required
          max={250}
        />
        <UI.Divider />
      </CmsPaper>
      <Buttons.Valid type="submit">Mettre à jour</Buttons.Valid>
    </CmsForm>
  );
};

export interface Settings extends IdLabel {
  greenfluxApiCdr: string;
  greenfluxUrl: string;
  greenfluxTpeTokenList: string;
  azureClientId: string;
  azureTenantId: string;
  totalDaisyUrl: string;
  totalDaisyUsername: string;
  aS24SftpLogin: string;
  aS24FetchEmailAddress: string;
  aS24SftpHost: string;
  geocodingUrl: string;
  geocodingKey: string;
  urgentTechUrl: string;
  urgentTechApiKeyCER: string;
  urgentTechApiSecretCER: string;
  urgentTechApiSecretENI: string;
  urgentTechApiKeyENI: string;
  urgentTechApiKeyQ8: string;
  urgentTechApiSecretQ8: string;
  mailingSendBySmtp: boolean;
  mailingDevEmail: string;
  mailingExtDevEmail: string;
  mailingFallbackDevEmail: string;
  mailingProfessionalDomain: string;
  mailingFallbackSupportEmail: string;
  mailingFallbackRHEmail: string;
}
