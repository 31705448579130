import React, { FC, Fragment, useContext, useState } from 'react';
import { Link } from '@mui/material';
import Typography from '@mui/material/Typography';
import MailIcon from '@mui/icons-material/Mail';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CommuteIcon from '@mui/icons-material/Commute';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import {
  AddNavigationButton,
  ArrowBackNavigationButton,
  EditNavigationButton,
  OpenInNewNavigationButton,
  PayLoadButton,
} from '../../component/shared/Buttons';
import { OsmMapShow } from '../../component/map/OsmMap';
import MapCustom from '../../component/map/GoogleMapCustom';
import EquipmentDiagram from '../../component/equipement/equipementDiagram/EquipmentDiagram';
import { DateCell } from '../../component/table/helper/customCells';
import {
  ChipInfo,
  CmsDivider,
  CmsPaper,
  HeaderPanel,
  LabelBlockValue,
  LabelValueUI,
  NoContentMessage,
  WarningBubble,
} from '../../component/shared/Ui';
import AccessFilter from '../../helper/AccessFilter';
import { ClStation } from '../../interface/ClType';
import {
  filterAD,
  getChartConfForData,
  localEquipmentColumn,
  localQuotationColumn,
  localQuotationRequestColumn,
} from './StationDetailUtils';
import { API_URL, BASE_URL } from '../../constant/API_URL';
import './station.scss';
import './../../style/theme.scss';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CmsChart from '../../component/shared/CmsChart';
import { GlobalContext } from '../../context/Global.context';
import { CmsFrontendTable } from '../../component/table/CmsTable';
import { AlarmListStation } from './AlarmList';
import { CmsMenuButton } from '../../component/shared/Menu';
import { JoinTableHandler } from '../../component/table/JoinTableHandler';
import APIRoute from '../../constant/API.constant';
import { ClContactConfigList } from '../billing/contact/ClContact.pack';
import { useNavigate } from 'react-router-dom';
import { ClClientConfigList } from '../billing/client/ClClient.pack';
import ROLE from '../../constant/role.constant';
import { TabStyleDataView } from '../../component/shared/TabStyleDataView';
import { LabelValue } from '../../interface/CommonType';
import Utils from '../../helper/Utils';
import { UI, InputUI } from '../../component/shared';
import { LazyLoadImage } from 'react-lazy-load-image-component';

/**
 * Détail de la page de la station, elle contient les informations générales, les coordonnées, les contacts,
 * les services, les équipements, les interventions, les alarmes, les devis et les demandes de devis
 * @param station station à afficher
 */
const StationDetailUi: FC<{ station: ClStation }> = ({ station }) => {
  const { theming } = useContext(GlobalContext);
  return (
    <Fragment>
      <HeaderPanel
        title={station.label}
        subtitle={`(${station.ref})`}
        actions={[
          <ArrowBackNavigationButton title="Retourner à la liste" to="/castres/client/station/list" />,
          <OpenInNewNavigationButton title="Voir les photos" to={`/castres/client/station/${station.id}/media`} />,
          AccessFilter([ROLE.ADMIN_PARK_STATION_EDIT]) && (
            <EditNavigationButton title="Éditer le site" to={`/castres/client/station/${station.id}/edit`} />
          ),
        ]}
      />

      {!station.active && (
        <WarningBubble style={{ marginBottom: '1rem', textAlign: 'center', fontSize: '2rem' }} customColor="#FF0000">
          Site inactif
        </WarningBubble>
      )}
      <div className="station-container" style={{ color: theming.get().palette.text.primary }}>
        {/* Left panel */}
        <div style={{ marginRight: '1.5em' }}>
          <General station={station} />
          <Coordonnees station={station} />
          <ContactsStation station={station} />
          <Services station={station} />
        </div>

        {/* Right panel */}
        <div>
          <Grid container spacing={2}>
            <Grid item sm={12} lg={8}>
              <Equipements station={station} />
            </Grid>
            <Grid item sm={12} lg={4}>
              <Commentaires station={station} />
            </Grid>
          </Grid>
          <Interventions station={station} />
          <Devis station={station} />
          <DemandesDeDevis station={station} />
          <Contrats station={station} />
          <Schemas station={station} />
          <Pannes station={station} />
          {!!station?.lvnetName && AccessFilter([ROLE.ADMIN_ALARMALX_VIEW]) && <AlarmListStation station={station} />}
        </div>
      </div>
    </Fragment>
  );
};

const GeneralLabelValue: LabelValue[] = [
  { label: 'Référence', value: (x: ClStation) => x.ref },
  { label: 'Nom', value: (x: ClStation) => <UI.TextEllipse text={x.label} /> },
  { label: 'Enseigne', value: (x: ClStation) => x.companyName },
  { label: 'Réseau', value: (x: ClStation) => x.networkName },
  {
    label: "Chargé d'affaire",
    value: (x: ClStation) =>
      `${x.accountManagerName}${x.accountManagerWorkphone ? ` (${x.accountManagerWorkphone})` : ''}`,
  },
  { label: 'Dispatcheur', value: (x: ClStation) => x.dispatcherName },
];

const ApiInseeLabelValue: LabelValue[] = [
  { label: 'Numéro SIRET', value: (x: ClStation) => x.siret },
  { label: 'SIRET maj par', value: (x: ClStation) => x.sirenUpdatedByName },
  { label: 'SIRET maj le', value: (x: ClStation) => Utils.displayDate(x.sirenUpdatedAt) },
  {
    label: 'Synchronisation API',
    value: (x: ClStation) => (x.lastApiSync ? Utils.displayDate(x.lastApiSync) : 'Jamais effectuée'),
  },
  { label: 'Fait par', value: (x: ClStation) => x.lastApiSyncByName },
];

/**
 * Information général de la station
 * @param station station à afficher
 */
const General: FC<{ station: ClStation }> = ({ station }) => (
  <CmsPaper title="Général" style={{ display: 'flex', flexDirection: 'column' }} tied>
    {station.imagePath && (
      <LazyLoadImage
        alt="Image de la station"
        effect="opacity"
        style={{ maxWidth: '100%', maxHeight: '200px', objectFit: 'contain' }}
        src={`${API_URL}${APIRoute.ClStation}/Picture/${station.id}`}
      />
    )}
    <TabStyleDataView conf={GeneralLabelValue} mapFrom={station} />
    <CmsDivider />
    <ChipInfo icon={<CheckCircleIcon />} label="Actif" isValid={station.active} />
    {/castres/.exec(BASE_URL) && (
      <ChipInfo icon={<AccountBalanceIcon />} label="Contrat CRIBE" isValid={station.contractCribe} />
    )}
    <ChipInfo icon={<AccessTimeIcon />} label="Astreinte" isValid={station.onCall} />
    <ChipInfo icon={<MailIcon />} label="Envoi des rapports par mail" isValid={station.sentMail} />
    {/castres/.exec(BASE_URL) && (
      <ChipInfo
        icon={<CommuteIcon />}
        label="Débit station ≥ 1500 m³/an ..."
        isValid={station.stationFlowSuperiorTo1500m3}
        tooltipLabel="Débit station ≥ 1500 m³/an ou aéroport, aérodrome ou port"
      />
    )}
    <TabStyleDataView conf={ApiInseeLabelValue} mapFrom={station} />
  </CmsPaper>
);

const listItemStyle = {
  border: '1px solid',
  borderRadius: '5px',
  paddingTop: 0,
  paddingBottom: 0,
  marginBottom: '0.5em',
};

/**
 * Les personnes à contacter de la station
 * @param station station à afficher
 */
const ContactsStation: FC<{ station: ClStation }> = ({ station }) => {
  const { theming } = useContext(GlobalContext);
  const actions = station.active ? [<AddClientOrContact station={station} />] : [];
  return (
    <CmsPaper title="Clients et Contacts" actions={actions} tied>
      {station.clientsContacts && station.clientsContacts?.length ? (
        <List style={{ paddingTop: 0, paddingBottom: 0 }}>
          {station.clientsContacts?.map((client) => (
            <ListItem key={`client-${client.id}`} style={listItemStyle}>
              <span style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <ListItemText
                  primary={
                    <Link style={{ color: 'inherit' }} href={BASE_URL + `castres/client/client/${client.id}/show`}>
                      {client.label}
                    </Link>
                  }
                  secondary={
                    <span style={{ display: 'flex', flexDirection: 'column' }}>
                      <LabelValueUI label="Référence" noSpacing>
                        {client.ref}
                      </LabelValueUI>
                    </span>
                  }
                />
                <List style={{ paddingTop: 0, paddingBottom: 0 }}>
                  {client.contacts?.map((contact) => (
                    <ListItem
                      key={`contact-${contact.id}`}
                      style={{
                        ...listItemStyle,
                        display: '',
                        border: 0,
                        backgroundColor: theming.get().cms.main.paperDif,
                      }}
                    >
                      <ListItemText
                        primary={
                          <Link href={BASE_URL + `castres/client/contact/${contact.id}/show`}>{contact.label}</Link>
                        }
                        secondary={
                          <span style={{ display: 'flex', flexDirection: 'column' }}>
                            {contact.email && (
                              <LabelValueUI label="E-mail" noSpacing>
                                <Link href={'mailto:' + contact.email}>{contact.email}</Link>
                              </LabelValueUI>
                            )}
                            {(contact.tel || contact.mobile) && (
                              <LabelValueUI label="Téléphone" noSpacing>
                                {contact.tel && <Link href={'tel:' + contact.tel}> {contact.tel}</Link>}
                                {contact.tel && contact.mobile && <span> / </span>}
                                {contact.mobile && <Link href={'tel:' + contact.mobile}>{contact.mobile}</Link>}
                              </LabelValueUI>
                            )}
                          </span>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </span>
            </ListItem>
          ))}
        </List>
      ) : (
        <NoContentMessage>Aucun contact à afficher</NoContentMessage>
      )}
    </CmsPaper>
  );
};

/**
 * Bouton pour ajouter un client ou un contact
 * @param station station à afficher
 */
const AddClientOrContact = ({ station }: { station: ClStation }) => {
  const navigate = useNavigate();
  return (
    <CmsMenuButton preventClose title="Gérer">
      <JoinTableHandler
        buttonLabel="Gérer les clients du site"
        originId={station.id}
        route={APIRoute.ClClient}
        columns={ClClientConfigList}
        originType={APIRoute.ClStation}
        onUpdate={() => navigate(0)}
        actions={[
          <PayLoadButton
            payload={{ stationId: station.id }}
            title="Ajouter un client"
            to="/castres/client/client/create"
          />,
        ]}
      />
      <JoinTableHandler
        buttonLabel="Gérer les contacts du site"
        originId={station.id}
        route={APIRoute.ClContact}
        columns={ClContactConfigList}
        originType={APIRoute.ClStation}
        onUpdate={() => navigate(0)}
        actions={[
          <PayLoadButton
            payload={{ stationId: station.id }}
            title="Ajouter un contact"
            to="/castres/client/contact/create"
          />,
        ]}
      />
    </CmsMenuButton>
  );
};

/**
 * Les coordonnées de la station
 * @param station station à afficher
 */
const Coordonnees: FC<{ station: ClStation }> = ({ station }) => {
  const [switchMap, setSwitchMap] = useState(false); //bouton permettant de switcher entre la map openstreetmap et la map google
  return (
    <CmsPaper title="Coordonnées" style={{ display: 'flex', flexDirection: 'column' }} tied>
      <LabelValueUI label="Manager">{station.manager}</LabelValueUI>
      <LabelValueUI label="E-mail">
        {station.email && <Link href={'mailto:' + station.email}>{station.email}</Link>}
      </LabelValueUI>
      <LabelValueUI label="Téléphone">
        {station.tel && <Link href={'tel:' + station.tel}> {station.tel}</Link>}
      </LabelValueUI>
      <LabelValueUI label="Département">{station.departmentName}</LabelValueUI>
      <LabelBlockValue label="Adresse">
        <p>{station.address}</p>
        <p>{`${station.postcode} ${station.city}`}</p>
      </LabelBlockValue>

      {!!station.latitude && !!station.longitude && (
        <>
          <CmsDivider />
          <InputUI.CmsSwitch
            inline
            label="OpenStreetMap/GoogleMap"
            value={switchMap}
            onChange={() => setSwitchMap(!switchMap)}
          />
          {!!switchMap ? (
            <MapCustom setCenter={{ lat: station.latitude, lng: station.longitude }} />
          ) : (
            <OsmMapShow setCenter={{ lat: station.latitude, lng: station.longitude }} />
          )}
        </>
      )}
    </CmsPaper>
  );
};

/**
 * Les services lié à la station
 * @param station station à afficher
 */
const Services: FC<{ station: ClStation }> = ({ station }) => (
  <CmsPaper title="Services" tied>
    <ul style={{ marginLeft: '1em' }}>
      {station.serviceLabel?.split(',').map((service, i) => <li key={`service-${i}`}>{service.trim()}</li>)}
    </ul>
  </CmsPaper>
);

/**
 * Commentaire de la station
 * @param station station à afficher
 */
const Commentaires: FC<{ station: ClStation }> = ({ station }) => (
  <CmsPaper title="Commentaires" style={{ textOverflow: 'ellipsis', overflowX: 'hidden' }} fluidHeight tied>
    {station.comment || <NoContentMessage>Aucun commentaire</NoContentMessage>}
  </CmsPaper>
);

/**
 * Les devis de la station
 * @param station station à afficher
 */

const Devis: FC<{ station: ClStation }> = ({ station }) => {
  return (
    <CmsFrontendTable
      title="Devis en cours"
      route="none"
      actions={[
        <OpenInNewNavigationButton
          access={[ROLE.ADMIN_QUOTATION_LIST]}
          title="Voir les devis"
          href={`${BASE_URL}castres/billing/quotation/list?t-stationRef=${station.ref}=1`}
        />,
        <AddNavigationButton
          hidden={!station.active}
          access={[ROLE.ADMIN_QUOTATION_CREATE]}
          title="Ajouter un devis"
          href={`${BASE_URL}castres/billing/quotation/create?station=${station.id}`}
        />,
      ]}
      showHeader="never"
      columns={localQuotationColumn}
      initialPageSize={10}
      controlledState={{ state: station.blQuotation ?? [], setState: () => {} }}
      navigateTo={(id: string | number) => BASE_URL + `castres/billing/quotation/${id}/show`}
    />
  );
};

/**
 * Les demandes de devis de la station
 * @param station station à afficher
 */
const DemandesDeDevis: FC<{ station: ClStation }> = ({ station }) => (
  <CmsFrontendTable
    actions={[
      <OpenInNewNavigationButton
        access={[ROLE.ADMIN_QUOTATION_REQUEST_LIST]}
        title="Voir les demandes de devis"
        href={`${BASE_URL}castres/billing/quotationrequest/list?filter[station__ref][type]=3&filter[station__ref][value]=${station.ref}`}
      />,
      <AddNavigationButton
        hidden={!station.active}
        access={[ROLE.ADMIN_QUOTATION_REQUEST_CREATE]}
        title="Ajouter une demande de devis"
        href={`${BASE_URL}castres/billing/quotationrequest/create?station=${station.id}`}
      />,
    ]}
    title="Demandes de devis"
    route="none"
    showHeader="never"
    initialPageSize={10}
    columns={localQuotationRequestColumn}
    controlledState={{ state: station.blQuotationRequest ?? [], setState: () => {} }}
    navigateTo={(id: string | number) => BASE_URL + `castres/billing/quotationrequest/${id}/show`}
  />
);

/**
 * Les équipements de la station
 * @param station station à afficher
 */
const Equipements: FC<{ station: ClStation }> = ({ station }) => {
  const actions = [];
  if (AccessFilter([ROLE.ADMIN_PARK_EQUIPMENT_LIST]))
    actions.push(
      <OpenInNewNavigationButton
        title="Voir les équipements"
        href={`${BASE_URL}castres/equipment/equipment/list?filter[station__ref][type]=3&filter[station__ref][value]=${station.ref}`}
      />,
    );
  if (AccessFilter([ROLE.ADMIN_CONFIGURATION_EQ_PISTOL_LIST]))
    actions.push(
      <OpenInNewNavigationButton
        title="Voir les pistolets"
        href={`${BASE_URL}castres/equipment/pistol/list?filter%5Bequipment__station__ref%5D%5Bvalue%5D=${station.ref}&filter%5Bequipment__contractLines__contract__endDate%5D%5Bvalue%5D=`}
      />,
    );
  return (
    <CmsFrontendTable
      actions={actions}
      title="Équipements"
      route="none"
      showHeader="never"
      initialPageSize={10}
      columns={localEquipmentColumn}
      controlledState={{ state: filterAD(station) ?? [], setState: () => {} }}
      navigateTo={(id: string | number) => `${BASE_URL}castres/equipment/equipment/${id}/show`}
    />
  );
};

/**
 * Les schema des équipements de la station (pompe généralement)
 * @param station station à afficher
 */
const Schemas: FC<{ station: ClStation }> = ({ station }) => (
  <CmsPaper
    title="Schémas"
    tied
    actions={[
      <span>
        <OpenInNewNavigationButton
          title="Voir les schémas"
          href={`${BASE_URL}castres/client/station/${station.id}/schema`}
        />
      </span>,
    ]}
  >
    <div
      className="styled-scroll"
      style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', maxHeight: '335px', overflowY: 'scroll' }}
    >
      {station?.eqEquipment && station?.eqEquipment.length ? (
        station.eqEquipment
          .filter((eq: any) => eq.eqPistolResources && eq.eqPistolResources.length)
          .map((eq: any, i: number) => (
            <div key={i}>
              <EquipmentDiagram key={i} equipement={eq} />
            </div>
          ))
      ) : (
        <NoContentMessage>Aucun équipement à afficher</NoContentMessage>
      )}
    </div>
  </CmsPaper>
);

/**
 * Les interventions de la station
 * @param station station à afficher
 */
const Interventions: FC<{ station: ClStation }> = ({ station }) => {
  const { theming } = useContext(GlobalContext);
  const getIndexedList = () => {
    if (!station.wkIntervention) return [];
    let indexedList = new Array<Array<any>>();
    for (let item of station.wkIntervention) {
      if (!indexedList[item.agreementId]) indexedList[item.agreementId] = [];
      indexedList[item.agreementId].push(item);
    }
    return indexedList;
  };

  return (
    <CmsPaper
      title="Interventions en cours ou planifiées"
      tied
      actions={[
        <span>
          {AccessFilter([ROLE.ADMIN_WORK_INTERVENTION_LIST]) && (
            <OpenInNewNavigationButton
              title="Historique station"
              href={`${BASE_URL}castres/work/intervention/list?t-siteRef=${station.ref}=1`}
            />
          )}
        </span>,
        <span>
          {AccessFilter([ROLE.ADMIN_WORK_INTERVENTION_LIST]) && (
            <OpenInNewNavigationButton
              title="Interventions en cours"
              href={`${BASE_URL}castres/work/intervention/list?t-siteRef=${station.ref}=1&t-completed=false&t-canceled=false`}
            />
          )}
        </span>,
        <span>
          <AddNavigationButton
            access={[ROLE.ADMIN_WORK_INTERVENTION_CREATE]}
            hidden={!station.active}
            title="Ajouter une intervention"
            href={`${BASE_URL}castres/work/intervention/create?stationId=${station.id}`}
          />
        </span>,
      ]}
    >
      {/*{station.wkIntervention && <ReactTable columns={localInterventionColumn} data={station.wkIntervention} />}*/}
      {station.wkIntervention && station.wkIntervention.length ? (
        <div>
          {getIndexedList().map((subList: Array<any>, i: number) => (
            <div key={i}>
              <Card
                variant="outlined"
                style={{
                  border: '1px solid rgb(42, 41, 42, 0.7)',
                  backgroundColor: 'transparent',
                  marginBottom: '0.5em',
                }}
              >
                <CardHeader
                  title={<Typography variant="body1">{subList[0].agreementLabel}</Typography>}
                  style={{
                    backgroundColor: theming.get().cms.main.cardHeader,
                    borderColor: theming.get().cms.main.cardHeader,
                    color: theming.get().palette.text.secondary,
                    padding: '0.2em 0.5em',
                  }}
                />
                <CardContent style={{ padding: '0.7em', display: 'flex', flexDirection: 'column' }}>
                  {subList.map((elem: any, j: number) => (
                    <Link
                      key={j}
                      style={{ color: 'inherit' }}
                      href={`${BASE_URL}castres/work/intervention/${elem.id}/show`}
                    >
                      <DateCell value={elem.creationDate} /> - {elem.name} ({elem.ref})
                    </Link>
                  ))}
                </CardContent>
              </Card>
            </div>
          ))}
        </div>
      ) : (
        <NoContentMessage>Aucune intervention en cours ou planifiée</NoContentMessage>
      )}
    </CmsPaper>
  );
};

/**
 * Les contrats de la station
 * @param station station à afficher
 */
const Contrats: FC<{ station: ClStation }> = ({ station }) => (
  <CmsPaper
    title="Récapitulatif des contrats"
    tied
    actions={[
      <span>
        {AccessFilter([ROLE.ADMIN_CONTRACT_LIST]) && (
          <OpenInNewNavigationButton
            title="Voir les contrats"
            href={`${BASE_URL}castres/billing/contract/list?filter[station__ref][type]=3&filter[station__ref][value]=${station.ref}`}
          />
        )}
      </span>,
    ]}
  >
    {station.blContract && station.blContract.length ? (
      station.blContract.map((contract: any, keyProp: number) => (
        <div key={keyProp} className="contract-list-generator">
          <h5>
            {contract.reference} - {contract.title}
          </h5>
          <ul>
            {contract.contractLineResource &&
              contract.contractLineResource.map((contractLine: any, key: number) => (
                <li key={key}>
                  {contractLine.chapterRef}.{contractLine.scheduleRef} - {contractLine.scheduleLabel} -{' '}
                  {contractLine.linePrice} €
                </li>
              ))}
          </ul>
        </div>
      ))
    ) : (
      <NoContentMessage>Aucun contrat à afficher</NoContentMessage>
    )}
  </CmsPaper>
);

/**
 * Composant générant le graphique des pannes.
 * @param station station à afficher
 */
const Pannes: FC<{ station: ClStation }> = ({ station }) => (
  <CmsPaper title="Taux de pannes critiques" style={{ maxHeight: '400px', paddingBottom: '60px' }} tied>
    {station.breakdowns && station.breakdowns.length ? (
      <CmsChart.Bar {...getChartConfForData(station.breakdowns)} />
    ) : (
      <NoContentMessage>Aucune donnée à afficher</NoContentMessage>
    )}
  </CmsPaper>
);
export default StationDetailUi;
