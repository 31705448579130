import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import APIRoute from '../../../constant/API.constant';
import CRUD from '../../../service/CRUD.service';
import { CmsImportPanel, CmsTreeView } from '../../../component/shared/ImportPanel';
import { Buttons, InputUI } from '../../../component/shared';
import { LabelValueIf, TabStyleDataView } from '../../../component/shared/TabStyleDataView';
import {
  BlPriceSchedule,
  BlPriceScheduleGroup,
  BlQuotationLine,
  BlQuotationStudyLine,
  BlReferenceStudyGroup,
  BlReferenceStudyLine,
  BlVat,
} from '../../../interface/BlType';
import Tooltip from '@mui/material/Tooltip';
import { CmsLink } from '../../../component/shared/Ui';
import { BlReferenceStudyTypeOptions } from '../../configuration/schedule/BlStudyCategory.pack';
import { IdLabel } from '../../../interface/CommonType';
import Utils from '../../../helper/Utils';
import { SkArticle } from '../../../interface/SkType';
import notificationService from '../../../service/NotificationService';

interface ImportPanelProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  handleImport: (importItem: any, asLine: boolean) => void;
}

const categories: IdLabel[] = [
  { id: 0, label: 'Bordereau' },
  { id: 1, label: "Référence d'étude" },
  { id: 2, label: 'Article' },
];

function mapRefStudyToTree(refStudy: BlReferenceStudyGroup): any {
  const children = refStudy.blReferenceStudyGroupLine?.map((x) => {
    return { ...x.line, id: 'line' + x.line.id, quantity: x.quantity * (x.line.quantityWithLoss ?? 0) };
  });
  return { ...refStudy, id: 'group' + refStudy.id, children };
}

function mapPriceScheduleToTree(group: any): any {
  return { ...group, id: 'group' + group.id, children: group.children.map(recursiveScheduleMapping) };
}

function recursiveScheduleMapping(line: any) {
  if ((line.children?.length ?? 0) === 0) line.id = 'line' + line.id;
  else line = { ...line, id: 'chapter' + line.id, children: line.children.map(recursiveScheduleMapping) };
  return line;
}

function findInRecursiveTree(selectedList: any[], id: string): any {
  for (let item of selectedList) {
    if (item.id === id) return item;
    if (!item.children) continue;
    const result = findInRecursiveTree(item.children, id);
    if (result) return result;
  }
}

// function getAllChildrenId(item: any): any[] {
//   const result = [];
//   for (let child of item.children ?? []) {
//     result.push(child.id);
//     result.push(...getAllChildrenId(child));
//   }
//   return result;
// }

function getAllChildrenIdRecursivly(item: any) {
  if (!item.children) return [item.id];
  return [item.id, ...item.children.map(getAllChildrenIdRecursivly).flat()];
}

const handleSelectionCascade = (items: any[], treeState: any, setTreeState: any, selectedList: any[], type: any) => {
  if (type === 2) return setTreeState(items);
  const newId = items.find((x) => !treeState.find((y: any) => y === x))?.toString();
  const oldId = treeState.find((x: any) => !items.find((y: any) => y === x))?.toString();
  if (!newId && !oldId) return;
  if (newId) {
    if (newId.startsWith('line')) return setTreeState(items);
    if (newId.startsWith('chapter')) {
      const missingLines = findInRecursiveTree(selectedList, newId)?.children?.map(getAllChildrenIdRecursivly).flat();
      return setTreeState(Utils.distinct([...items, ...(missingLines ?? [])]));
    }
    const newGroup = selectedList.find((x: any) => x.id === newId);
    if (!newGroup?.children) return notificationService.error('Groupe non trouvé');
    const missingLines = newGroup.children.map(getAllChildrenIdRecursivly).flat();
    return setTreeState(Utils.distinct([...missingLines, newId]));
  } else {
    if (oldId.startsWith('line')) return setTreeState(items);
    if (oldId.startsWith('chapter')) {
      const missingLines = findInRecursiveTree(selectedList, oldId)?.children?.map(getAllChildrenIdRecursivly).flat();
      if (missingLines?.length > 0)
        return setTreeState(items.filter((x: any) => !missingLines.find((y: any) => y === x)));
    }
    setTreeState([]);
    // const group = selectedList.find((x: any) => x.id === oldId);
    // const chapterList = group.children.map((x: any) => x.id);
    // const lineList = group.children.map(getAllChildrenIdRecursivly).flat();
    // setTreeState(items.filter((x: any) => !chapterList.includes(x) && !lineList.includes(x)));
  }
};

export const QuotationImportPanel: FC<ImportPanelProps> = ({ open, setOpen, handleImport }) => {
  const [selectedButton, setSelectedButton] = useState(0);
  const [search, setSearch] = useState<string | undefined>();
  const [priceScheduleList, setPriceScheduleList] = useState<any[]>([]);
  const [refStudyList, setRefStudyList] = useState<any[]>([]);
  const [articleList, setArticleList] = useState<any[]>([]);
  const [treeState, setTreeState] = useState<any[]>([]);
  const [vat20, setVat20] = useState<BlVat>();

  useEffect(() => {
    CRUD.getList<BlVat>(APIRoute.BlVat).then((x) => {
      console.log('x', x, x.find((x) => x.value === 0.2) as BlVat);
      setVat20(x.find((x) => x.value === 0.2) as BlVat);
    });
  }, []);

  const handleChangeCategory = (index: number) => {
    setSelectedButton(index);
    setTreeState([]);
    handleSearch(search);
  };

  const handleSearch = (search: string | undefined) => {
    setSearch(search);
    if (!search || search.length < 3) return;
    const payload = [APIRoute.BlPriceScheduleGroup, APIRoute.BlReferenceStudyGroup, APIRoute.SkArticle];
    CRUD.getList<any>(payload[selectedButton] + '/WithData/Suggested?search=' + search).then((x) => {
      if (selectedButton === 2) return setArticleList(x);
      if (selectedButton === 1) return setRefStudyList(x.map(mapRefStudyToTree));
      setPriceScheduleList(x.map(mapPriceScheduleToTree));
    });
  };

  const handleCardClick = (item: any) => {
    let treeStateTemp = [...treeState];
    treeStateTemp = treeState.find((x) => x === item.id)
      ? treeStateTemp.filter((x) => x !== item.id)
      : [...treeStateTemp, item.id];
    handleSelectionCascade(treeStateTemp, treeState, setTreeState, selectedList, selectedButton);
  };

  const handleImportClick = () => {
    if (selectedButton === 2) importArticle(true, treeState, handleImport, vat20);
    if (selectedButton === 1) importRefStudy(true, treeState, handleImport);
    if (selectedButton === 0) importPriceSchedule(true, treeState, handleImport);
  };

  const selectedList = [priceScheduleList, refStudyList, articleList][selectedButton];
  return (
    <CmsImportPanel {...{ open, setOpen }} title={<h3>Panneau d'import</h3>} panelWidth="40rem">
      <div className="flex-h-bet" style={{ marginBottom: '.5rem' }}>
        <InputUI.CmsButtonGroup value={selectedButton} options={categories} onClick={handleChangeCategory} />
        <Buttons.Valid onClick={handleImportClick}>Importer</Buttons.Valid>
      </div>
      <div>
        <InputUI.DebouncedInput value={search} valueCanUpdate onChange={handleSearch} placeholder="3 lettres minimum" />
        <CmsTreeView
          tree={selectedList}
          card={[PriceScheduleCard, RefStudyCard, ArticleCard][selectedButton]}
          onCardClick={handleCardClick}
          stateHandler={{
            state: treeState,
            setState: (x) => handleSelectionCascade(x, treeState, setTreeState, selectedList, selectedButton),
          }}
          multiselect={selectedButton !== 2}
          checkboxSelection={selectedButton !== 2}
        />
        {!selectedList || (selectedList.length === 0 && <h2 className="flex-center">Aucun résultat</h2>)}
      </div>
    </CmsImportPanel>
  );
};

const PriceScheduleDataList: LabelValueIf[] = [
  { label: 'Nom', value: (x: any) => x.label },
  { label: 'Type', value: (x: any) => x.data?.typeLabel },
  { label: 'Unité', value: (x: any) => x.data?.unitLabel },
  { label: 'Prit unitaire', value: (x: any) => Utils.ThousandSpacing(x.data?.price ?? 0, 2) + ' €' },
];

const PriceScheduleLineToolTip: FC<{ line: any }> = ({ line }) => {
  const lineData: BlPriceSchedule = line.data;
  return (
    <div>
      <TabStyleDataView conf={PriceScheduleDataList} mapFrom={line} />
      <div>Contient les lignes:</div>
      {lineData.blPriceScheduleLineGroup?.map((x, i) => (
        <div key={i} style={{ backgroundColor: i % 2 ? 'transparent' : 'rgba(255,255,255, 0.1)' }}>
          <Tooltip title={<TabStyleDataView conf={RefStudyGroupDataList} mapFrom={x.group} />} placement="left">
            <div className="card-label">
              <CmsLink
                href={`/castres/billing/referencestudygroup/${x.groupId}/edit`}
                label={x.group.label}
                target="_blank"
                defaultStyle
              />
            </div>
          </Tooltip>
        </div>
      ))}
    </div>
  );
};

const PriceScheduleChapterToolTip: FC<{ line: any }> = ({ line }) => {
  return (
    <div>
      <CmsLink
        href={`/castres/billing/priceschedule/${line.data.groupId}/edit`}
        label={line.label}
        target="_blank"
        defaultStyle
      />
      <div>Contient les lignes :</div>
      {line.children?.map((x: any, i: number) => (
        <div key={i} style={{ backgroundColor: i % 2 ? 'transparent' : 'rgba(255,255,255, 0.1)' }}>
          <Tooltip title={<PriceScheduleLineToolTip line={x} />} placement="left">
            <div className="card-label">
              <CmsLink
                href={`/castres/billing/priceschedule/${line.id}/edit`}
                label={x.label}
                target="_blank"
                defaultStyle
              />
            </div>
          </Tooltip>
        </div>
      ))}
    </div>
  );
};

const PriceScheduleGroupDataList: LabelValueIf[] = [
  { label: 'Référence', value: (x: any) => x.ref },
  { label: 'Nom', value: (x: any) => x.label },
  { label: 'Type', value: (x: any) => x.typeLabel },
];

const PriceScheduleGroupToolTip: FC<{ line: any }> = ({ line }) => {
  return (
    <div>
      <TabStyleDataView conf={PriceScheduleGroupDataList} mapFrom={line} />
      <div>Contient les chapitre:</div>
      {line.children?.map((x: any, i: number) => (
        <div key={i} style={{ backgroundColor: i % 2 ? 'transparent' : 'rgba(255,255,255, 0.1)' }}>
          <Tooltip title={<PriceScheduleChapterToolTip line={x} />} placement="left">
            <div className="card-label">
              <CmsLink
                href={`/castres/billing/priceschedule/${line.id}/edit`}
                label={x.label}
                target="_blank"
                defaultStyle
              />
            </div>
          </Tooltip>
        </div>
      ))}
    </div>
  );
};

const PriceScheduleCard: FC = (line: any, onClick) => {
  if (!!line.data.priceScheduleGroupTypeId)
    return (
      <div className="import-card" onClick={() => onClick(line)} style={{ color: '#61daff' }}>
        <Tooltip title={<PriceScheduleGroupToolTip line={line} />} placement="left">
          <span className="card-label">{line.label}</span>
        </Tooltip>
      </div>
    );
  if (!!line.data.chapter)
    return (
      <div className="import-card" onClick={() => onClick(line)} style={{ color: '#61daff' }}>
        <Tooltip title={<PriceScheduleChapterToolTip line={line} />} placement="left">
          <span className="card-label">{line.label}</span>
        </Tooltip>
      </div>
    );
  return (
    <div className="import-card" onClick={() => onClick(line)} style={{ color: '#61daff' }}>
      <Tooltip title={<PriceScheduleLineToolTip line={line} />} placement="left">
        <span className="card-label">{line.label}</span>
      </Tooltip>
    </div>
  );
};

const ArticleDataList: LabelValueIf[] = [
  {
    label: 'Nom',
    value: (x: any) => (
      <CmsLink href={`/castres/stock/article/${x.id}/edit`} label={x.label} target="_blank" defaultStyle />
    ),
  },
  { label: 'Prix', value: (x: any) => Utils.ThousandSpacing(x.price ?? 0, 2) + ' €' },
  { label: 'Fournisseur', value: (x: any) => x.companyLabel },
];

const ArticleCard: FC = (line: any, onClick) => (
  <div className="import-card" onClick={() => onClick(line)} style={{ width: '35rem' }}>
    <Tooltip title={<TabStyleDataView conf={ArticleDataList} mapFrom={line} />} placement="left">
      <div className="card-label">{line.label}</div>
    </Tooltip>
  </div>
);

export const RefStudyDataList: LabelValueIf[] = [
  {
    label: 'Nom',
    value: (x: BlReferenceStudyLine) => (
      <CmsLink
        href={`/castres/billing/referencestudyline/${x.id.toString().match(/\d+/)?.[0]}/edit`}
        label={x.label}
        target="_blank"
        defaultStyle
      />
    ),
  },
  { label: 'Type', value: (x: BlReferenceStudyLine) => BlReferenceStudyTypeOptions[x.type ?? 0]?.label },
  { label: 'Montant H.T', value: (x: BlReferenceStudyLine) => Utils.ThousandSpacing(x.costPrice, 2) + ' €' },
  { label: 'Prix vente', value: (x: BlReferenceStudyLine) => Utils.ThousandSpacing(x.markupPrice, 2) + ' €' },
  { label: "Corps d'état", value: (x: BlReferenceStudyLine) => x.jobLabel },
  { label: 'Quantité', value: (x: BlReferenceStudyLine) => x.quantity },
  { label: 'Perte', value: (x: BlReferenceStudyLine) => (x.lossFactor ?? 0) + '%' },
  { label: 'Unité', value: (x: BlReferenceStudyLine) => x.unitLabel },
  { label: "Catégorie d'étude", value: (x: BlReferenceStudyLine) => x.categoryLabel },
  {
    label: 'Article',
    value: (x: BlReferenceStudyLine) => (
      <CmsLink
        href={`/castres/stock/article/${x.articleId}/edit`}
        label={x.articleLabel ?? ''}
        target="_blank"
        defaultStyle
      />
    ),
    ifIs: (x: BlReferenceStudyLine) => !!x.articleId,
  },
];

const RefStudyGroupDataList: LabelValueIf[] = [
  {
    label: 'Nom',
    value: (x: BlReferenceStudyLine) => (
      <CmsLink
        href={`/castres/billing/referencestudygroup/${x.id.toString().match(/\d+/)?.[0]}/edit`}
        label={x.label}
        target="_blank"
        defaultStyle
      />
    ),
  },
  { label: 'Unité', value: (x: BlReferenceStudyGroup) => x.unitLabel },
  { label: 'P.revient', value: (x: BlReferenceStudyGroup) => Utils.ThousandSpacing(x.costPrice ?? 0, 2) + ' €' },
  { label: 'Invariable', value: (x: BlReferenceStudyGroup) => Utils.ThousandSpacing(x.flatRateCost ?? 0, 2) + ' €' },
  { label: 'Variable', value: (x: BlReferenceStudyGroup) => Utils.ThousandSpacing(x.variableRateCost ?? 0, 2) + ' €' },
  { label: 'Prix vente', value: (x: BlReferenceStudyGroup) => Utils.ThousandSpacing(x.markupPrice ?? 0, 2) + ' €' },
];

const RefStudyTooltip: FC<{ line: BlReferenceStudyGroup }> = ({ line }) => {
  if (line.id.toString().includes('line')) return <TabStyleDataView conf={RefStudyDataList} mapFrom={line} />;
  return (
    <div>
      <TabStyleDataView conf={RefStudyGroupDataList} mapFrom={line} />
      <div>Contient les lignes:</div>
      {line.blReferenceStudyGroupLine?.map((x, i) => (
        <div key={i} style={{ backgroundColor: i % 2 ? 'transparent' : 'rgba(255,255,255, 0.1)' }}>
          <Tooltip title={<TabStyleDataView conf={RefStudyDataList} mapFrom={x.line} />} placement="left">
            <div className="card-label">
              <CmsLink
                href={`/castres/billing/referencestudyline/${x.line.id}/edit`}
                label={`${x.quantity ?? 0} x ${x.line.label}`}
                target="_blank"
                defaultStyle
              />
            </div>
          </Tooltip>
        </div>
      ))}
    </div>
  );
};

const RefStudyCard: FC = (line: any, onClick) => (
  <div className="import-card" onClick={() => onClick(line)} style={{ width: '33rem' }}>
    <Tooltip title={<RefStudyTooltip line={line} />} placement="left">
      <div className="card-label">{line.label}</div>
    </Tooltip>
  </div>
);

const importArticle = (asLine: boolean, id: any, handleImport: any, vat20?: BlVat) => {
  CRUD.getById<SkArticle>(APIRoute.SkArticle, id).then((x) => {
    const studyLine: BlQuotationStudyLine | any = {
      label: x.label,
      unitPrice: x.price?.toFixed(2),
      quantity: 1,
      unitId: x.unitId,
      articleId: x.id,
      articleLabel: x.label,
      article: x,
    };
    if (!asLine) return handleImport(studyLine, false);
    const newQuotationLine: BlQuotationLine = {
      label: x.label,
      // price: x.price, // Ne pas renseigner pas défault le prix de l'article
      quantity: 1,
      unit: x.unit,
      vatId: vat20?.id,
      vatValue: vat20?.value,
      blQuotationStudyLine: [studyLine],
    };
    handleImport(newQuotationLine, true);
  });
};

const importRefStudy = (asLine: boolean, id: any[], handleImport: any) => {
  const groupId = id.find((x) => x.startsWith('group'))?.replace('group', '');
  if (!groupId) {
    const idList = id.filter((x) => x.startsWith('line')).map((x) => +x.replace('line', ''));
    CRUD.post<BlReferenceStudyLine[]>(APIRoute.BlReferenceStudyLine + '/GetRange', { list: idList }).then((lines) => {
      let result = [];
      for (let line of lines) {
        result.push({
          id: Utils.getNewId([]),
          label: line.label,
          price: line.markupPrice,
          quantity: 1,
          unit: line.unitId,
          type: line.type,
          referenceStudyLineId: line.id,
          blQuotationStudyLine: [{ ...line, unitPrice: line.markupPrice }],
          categoryId: line.categoryId,
        });
      }
      handleImport(result, true);
    });
    return;
  }
  CRUD.getById<BlReferenceStudyGroup>(APIRoute.BlReferenceStudyGroup, groupId).then((x) => {
    const lineIdList = id.filter((x) => x.startsWith('line')).map((x) => x.replace('line', ''));
    const lines = x.blReferenceStudyGroupLine
      ?.filter((x) => lineIdList.includes(x.lineId.toString()))
      .map((x) => {
        return {
          ...x.line,
          id: Utils.getNewId([]),
          unitPrice: x.quantity * x.line.markupPrice,
          quantity: x.quantity * x.line.quantity,
          lossFactor: x.line.lossFactor,
          amount: x.quantity * (x.line.markupPrice ?? 0),
          referenceStudyLineId: x.line.id,
          isFlatRate: x.isFlatRate,
          type: x.line.type,
          categoryId: x.line.categoryId,
        };
      });
    const newQuotationLine = {
      id: Utils.getNewId([]),
      label: x.label,
      price: x.markupPrice,
      quantity: 1,
      unit: x.unitId,
      blQuotationStudyLine: lines,
    };
    handleImport(newQuotationLine, true);
  });
};

const importPriceSchedule = (asLine: boolean, id: any[], handleImport: any) => {
  const groupId = id.find((x) => x.startsWith('group'))?.replace('group', '');
  if (!groupId) return;
  CRUD.getById<BlPriceScheduleGroup>(APIRoute.BlPriceScheduleGroup, groupId).then((x) => {
    let lines: any[] = [{ label: x.label, chapter: true, indent: 1, id: Utils.getNewId([]) }];
    for (let line of x.blPriceSchedule ?? []) {
      if (id.includes('chapter' + line.id))
        lines.push({ ...line, id: Utils.getNewId([]), chapter: true, indent: line.chapterLevel });
      if (id.includes('line' + line.id)) lines.push(mapPriceScheduleChildren(line));
    }
    handleImport(lines, true);
  });
};

function mapPriceScheduleChildren(priceSchedule: BlPriceSchedule) {
  return {
    ...priceSchedule,
    id: Utils.getNewId([]),
    quantity: 1,
    blQuotationStudyLine: priceSchedule.blPriceScheduleLineGroup
      ?.map(
        (x) =>
          x.group?.blReferenceStudyGroupLine?.map((y) => {
            return {
              ...y.line,
              isFlatRate: y.isFlatRate,
              id: Utils.getNewId([]),
              quantity: (x.quantity ?? 1) * y.quantity * (y.line.quantityWithLoss ?? 0),
            };
          }) ?? [],
      )
      .flat(),
  };
}

// const MiniWarningIcon: FC = () => <CmsIcon icon="warning" style={{ color: 'yellow', fontSize: '1rem' }} />;
