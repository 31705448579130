import React, { FC } from 'react';
import { CmsBackendTable, CmsColumnDef } from '../../../component/table/CmsTable';
import { Link } from '@mui/material';
import AccessFilter from '../../../helper/AccessFilter';
import { WkTask } from 'interface/WkType';
import APIRoute from 'constant/API.constant';
import CmsTableFilter, { CmsTableFilterType } from 'component/table/helper/CmsTableFilter';
import { BASE_URL } from 'constant/API_URL';
import CmsTableCell from 'component/table/helper/CmsTableCell';
import Utils from '../../../helper/Utils';
import ROLE from '../../../constant/role.constant';
import CmsIcon from 'component/shared/CmsIcon';

const columns: CmsColumnDef<WkTask>[] = [
  {
    header: 'Nom',
    id: 'name',
    cell: (info) => (
      <Link
        key={info.row.original?.id}
        style={{ color: 'inherit' }}
        href={`${BASE_URL}work/report/upload/${info.row.original?.id}`}
        target="_blank"
      >
        {info.getValue()}
      </Link>
    ),
    Filter: CmsTableFilter.Text,
  },
  { header: 'Code implant site', id: 'stationRef', Filter: CmsTableFilter.Text, hide: true },
  { header: 'Nom du site', id: 'stationLabel', Filter: CmsTableFilter.Text, hide: true },
  {
    header: 'Site',
    id: 'station',
    cell: (info) => (
      <Link
        key={info.getValue()?.id}
        style={{ color: 'inherit' }}
        href={`${BASE_URL}castres/client/station/${info.getValue()?.id}/show`}
        target="_blank"
      >
        <div style={{ fontWeight: 'bold' }}>{info.getValue()?.ref}</div>
        <div>{info.getValue()?.name}</div>
      </Link>
    ),
  },
  { header: 'Code postal', id: 'stationPostcode', Filter: CmsTableFilter.Text, hide: 'hiddenByDefault' },
  { header: 'Réf. Intervention', id: 'interventionRef', Filter: CmsTableFilter.Text, hide: true },
  {
    header: 'Intervention',
    id: 'intervention',
    cell: (info) => (
      <Link
        key={info.getValue()?.id}
        style={{ color: 'inherit' }}
        href={`${BASE_URL}castres/work/intervention/${info.getValue()?.id}/show`}
        target="_blank"
      >
        <div style={{ fontWeight: 'bold' }}>{info.getValue()?.ref}</div>
        <div>{info.row.original?.interventionService}</div>
      </Link>
    ),
  },
  {
    header: 'Enseigne',
    id: 'stationCompany',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.ClCompany },
    hide: 'hiddenByDefault',
  },
  {
    header: 'Réseau',
    id: 'stationNetwork',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.ClNetwork },
    hide: 'hiddenByDefault',
  },
  {
    header: 'Service intervention',
    hide: true,
    id: 'interventionService',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.SfService, optionFrom: 'WkTask' },
  },
  {
    header: 'Agence intervention',
    hide: true,
    id: 'interventionAgency',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.ClAgency, optionFrom: 'WkTask' },
  },
  {
    header: 'Type de contrat',
    id: 'interventionAgreement',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.WkAgreement, multiple: true },
    hide: 'hiddenByDefault',
  },
  {
    header: 'Conducteur de travaux',
    id: 'interventionSupervisedByName',
    Filter: CmsTableFilter.Select,
    filterOptions: {
      optionList: `${APIRoute.WkIntervention}/Supervisors`,
      optionLabel: 'nameReversed',
      rawEndpoint: true,
    },
    hide: 'hiddenByDefault',
  },
  {
    header: 'Dispatcheur',
    id: 'dispatcher',
    Filter: CmsTableFilter.Select,
    filterOptions: {
      optionList: `${APIRoute.ClStation}/Dispatcher`,
      optionLabel: 'nameReversed',
      rawEndpoint: true,
    },
    hide: true,
  },
  {
    header: 'Équipe',
    id: 'team',
    cell: (info) => (
      <>
        <div style={{ fontWeight: 'bold' }}>{info.row.original?.team}</div>
        <div>{info.row.original?.teamService}</div>
      </>
    ),
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.SfTeam },
  },
  {
    header: 'Service équipe',
    id: 'teamService',
    hide: true,
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.SfService },
  },
  {
    header: "Corps d'état",
    id: 'job',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.WkJob },
    size: 100,
  },
  {
    header: 'Commence le',
    id: 'startDate',
    cell: CmsTableCell.Datetime,
    Filter: CmsTableFilter.Date,
    defaultFilterValue: {
      type: CmsTableFilterType.DateBetween,
      value: { start: Utils.Date.pureDate(new Date()), end: Utils.Date.pureDate(Utils.Date.addDays(new Date(), 1)) },
    },
  },
  { header: 'Arrivée sur site', id: 'workStart', cell: CmsTableCell.Datetime, hide: 'hiddenByDefault' },
  { header: 'Fin de tâche', id: 'endDate', cell: CmsTableCell.Datetime, hide: 'hiddenByDefault' },
  {
    header: 'Date butoir intervention',
    id: 'interventionDeadline',
    cell: CmsTableCell.Datetime,
    Filter: CmsTableFilter.Date,
  },
  {
    header: 'Date de résolution Client',
    id: 'interventionClientResolvedDate',
    cell: CmsTableCell.Datetime,
  },
  {
    header: 'Date envoi CRI',
    id: 'interventionCriSentDate',
    cell: CmsTableCell.Datetime,
  },
  {
    header: 'Domaine',
    id: 'domain',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.EqDomain + '/Simplified?includeInactive=true', rawEndpoint: true },
    hide: 'hiddenByDefault',
  },
  {
    header: 'Nature',
    id: 'nature',
    hide: 'hiddenByDefault',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.EqNature },
  },
  { header: 'Effet', id: 'effect', hide: 'hiddenByDefault' },
  { header: 'Action', id: 'action', hide: 'hiddenByDefault' },
  {
    header: 'Satisfaction client',
    id: 'customerSatisfaction',
    cell: CmsTableCell.Rating,
    Filter: CmsTableFilter.Select,
    filterOptions: {
      optionList: [
        { id: 1, label: 'Très satisfait' },
        { id: 2, label: 'Satisfait' },
        { id: 3, label: 'Moyennement satisfait' },
        { id: 4, label: 'Insatisfait' },
      ],
      optionLabel: 'label',
    },
    hide: 'hiddenByDefault',
  },
  {
    header: 'Temps de route',
    id: 'roadtime',
    cell: CmsTableCell.DurationHours,
    Filter: CmsTableFilter.Number,
    size: 10,
  },
  {
    header: 'Temps de travail',
    id: 'worktime',
    cell: CmsTableCell.DurationHours,
    Filter: CmsTableFilter.Number,
    size: 10,
  },
  { header: 'Kilomètres parcourus', id: 'distance', Filter: CmsTableFilter.Number, size: 10 },
  { header: "Taille de l'équipe", id: 'teamsize', Filter: CmsTableFilter.Number, hide: 'hiddenByDefault', size: 10 },
  { header: 'Vue', id: 'vue', Filter: CmsTableFilter.Bool, size: 10 },
  { header: 'Clôturée', id: 'closed', Filter: CmsTableFilter.Bool, hide: 'hiddenByDefault', size: 10 },
  {
    header: 'Date de clôture',
    id: 'closedAt',
    cell: CmsTableCell.Datetime,
    Filter: CmsTableFilter.Date,
    hide: 'hiddenByDefault',
  },
  {
    header: 'Type de rapport',
    id: 'wkReportType',
    accessorKey: 'wkReportTypeName',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.WkReportType },
    hide: 'hiddenByDefault',
  },
  { header: 'Nombre pistolet VP', id: 'pistolVP', hide: 'hiddenByDefault', size: 10 },
  { header: 'Nombre TPI', id: 'tpi', hide: 'hiddenByDefault', size: 10 },
  { header: 'Nombre BIP', id: 'bip', hide: 'hiddenByDefault', size: 10 },
  { header: 'Nombre POS', id: 'pos', hide: 'hiddenByDefault', size: 10 },
];

export const TaskList: FC = () => {
  return (
    <CmsBackendTable
      title="Liste des tâches"
      columns={columns}
      route={APIRoute.WkTask}
      globalFilterIcon={
        <CmsIcon
          icon="warning"
          typeColor="primary"
          tooltip="Attention, ce filtre ne s'applique que sur la référence du Site et de l'Intervention"
        />
      }
      setFiltersInUrl
      canExport={AccessFilter([ROLE.ADMIN_WORK_TASK_EXPORT])}
    />
  );
};
