import React, { FC, useEffect, useState } from 'react';
import { CmsBackendTable, CmsColumnDef } from '../../../component/table/CmsTable';
import APIRoute from 'constant/API.constant';
import CmsTableFilter from 'component/table/helper/CmsTableFilter';
import { BASE_URL } from 'constant/API_URL';
import CmsTableCell from 'component/table/helper/CmsTableCell';
import CmsIcon from 'component/shared/CmsIcon';
import { InputUI } from 'component/shared';
import CRUD from 'service/CRUD.service';
import { CellContext } from '@tanstack/react-table';
import { WkInterventionNote } from 'interface/WkType';

const columns: CmsColumnDef<WkInterventionNote>[] = [
  {
    header: 'À lire',
    id: 'acknowledged',
    cell: (info) => CellAcknowledged(info),
    size: 10,
  },
  {
    header: 'Créé le',
    id: 'creationDate',
    cell: CmsTableCell.DateBigTime,
    Filter: CmsTableFilter.Date,
    size: 10,
  },

  {
    header: 'Référence intervention',
    id: 'interventionRef',
    Filter: CmsTableFilter.Text,
    hide: true,
  },
  {
    header: "Nom de l'intervention",
    id: 'interventionLabel',
    Filter: CmsTableFilter.Text,
    hide: true,
  },
  {
    header: 'Voir les notes auto-générées',
    id: 'showAutoGenerated',
    Filter: CmsTableFilter.Bool,
    hide: true,
    defaultFilterValue: () => false,
  },
  {
    header: 'Auteur de la note',
    id: 'writerName',
    hide: 'hiddenByDefault',
    Filter: CmsTableFilter.Text,
    cell: (info) =>
      !!info.row.original.writerId ? (
        <CmsTableCell.Ref cell={info} url={`${BASE_URL}sonata/user/user/${info.row.original.writerId}/show`} />
      ) : (
        <>{info.getValue()}</>
      ),
  },
  {
    header: 'Intervention',
    id: 'intervention',
    cell: (info) => (
      <CmsTableCell.Ref
        cell={info}
        url={`${BASE_URL}castres/work/intervention/${info.row.original.wkInterventionId}/show`}
        value={info.row.original.wkInterventionLabel}
      />
    ),
  },

  {
    header: 'Station',
    id: 'stationId',
    cell: (info) => (
      <CmsTableCell.Ref
        cell={info}
        url={`${BASE_URL}castres/client/station/${info.row.original.stationId}/show`}
        value={info.row.original.stationLabel}
      >
        {info.row.original.stationDepartment} - {info.row.original.stationCity}
      </CmsTableCell.Ref>
    ),
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.ClStation },
  },
  {
    header: 'Pétrolier',
    id: 'company',
    hide: 'hiddenByDefault',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.ClCompany, multiple: true },
  },
  {
    header: 'Source',
    id: 'source',
    hide: 'hiddenByDefault',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.WkSource, multiple: true },
  },
  {
    header: 'Conducteur de travaux',
    id: 'supervisedBy',
    hide: 'hiddenByDefault',
    Filter: CmsTableFilter.Select,
    filterOptions: {
      optionList: `${APIRoute.WkIntervention}/Supervisors`,
      optionLabel: 'nameReversed',
      rawEndpoint: true,
      multiple: true,
    },
    cell: (info) => <span>{info.row.original.supervisedByName}</span>,
  },
  {
    header: 'Dispatch',
    id: 'dispatch',
    hide: 'hiddenByDefault',
    Filter: CmsTableFilter.Select,
    filterOptions: {
      optionList: `${APIRoute.ClStation}/Dispatcher`,
      optionLabel: 'nameReversed',
      rawEndpoint: true,
      multiple: true,
    },
    cell: (info) => <span>{info.row.original.dispatchName}</span>,
  },
  {
    header: 'Contenu de la note',
    id: 'Note',
    cell: (info) => (
      <div style={{ display: 'flex' }}>
        <div style={{ whiteSpace: 'pre-wrap', maxHeight: '6rem', overflow: 'auto' }}>{info.row.original.content}</div>
      </div>
    ),
    size: 500,
    noSorting: true,
  },
  {
    header: 'Pièce-jointe',
    id: 'attachment',
    cell: (info) => {
      if (info.row.original.attachmentPath)
        return (
          <CmsIcon
            icon="download"
            href={`/api/${APIRoute.WkTotalEnergies}/attachment/${info.row.original.id}`}
            target="_blank"
            tooltip={'Télécharger la pièce jointe'}
          />
        );
      else return <CmsIcon icon="KO" typeColor="error" />;
    },
    size: 10,
  },
];

const CellAcknowledged: FC<CellContext<WkInterventionNote, any>> = (info: CellContext<WkInterventionNote, any>) => {
  const [needAttention, setNeedAttention] = useState<boolean>(!info.row.original.acknowledged);
  useEffect(() => {
    setNeedAttention(!info.row.original.acknowledged);
  }, [info]);

  return (
    <InputUI.CmsSwitch
      value={needAttention}
      onChange={() =>
        CRUD.post(APIRoute.WkInterventionNote + '/Acknowledge/', {
          id: info.row.original.id,
          value: needAttention,
        }).then((result: any) => {
          if (result.id === info.row.original.id) setNeedAttention(!result.acknowledged);
        })
      }
    />
  );
};

export const InterventionNoteList: FC = () => {
  return (
    <CmsBackendTable
      title="Liste des notes d'interventions"
      columns={columns}
      route={APIRoute.WkInterventionNote}
      invertClick
      actions={[
        <CmsIcon key="help" href="/help/Intervention/intervention_notes" icon="info" tooltip="Aide" target="_blank" />,
      ]}
      globalFilterIcon={
        <CmsIcon
          icon="warning"
          typeColor="primary"
          tooltip={
            <div>
              <h3 style={{ textAlign: 'center' }}>Attention </h3>
              Ce filtre ne s'applique que sur les champs Identifiant, référence de l'intervention ou de la station, nom
              de l'intervention ou de la station, et nom du chargé d'affaires
            </div>
          }
        />
      }
      setFiltersInUrl
    />
  );
};
