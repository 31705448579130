import React, { Fragment, FC } from 'react';
import './../../style/theme.scss';
import { WkGreenfluxCdr } from 'interface/WkType';
import { UI } from 'component/shared';
import { ArrowBackNavigationButton, NavigationButton } from 'component/shared/Buttons';
import AccessFilter from '../../helper/AccessFilter';
import './greenflux.scss';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Card } from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import Utils from 'helper/Utils';
import ROLE from '../../constant/role.constant';

/**
 * Page de mis en forme des informations d'un CDR en base
 * @param cdr Données reçu du CDR
 */
const CdrDetailUi: FC<{ cdr: WkGreenfluxCdr }> = ({ cdr }) => (
  <Fragment>
    <UI.HeaderPanel
      title={cdr.idCdr}
      subtitle={cdr.stationGreenfluxName + ', ' + cdr.stationCmsRef}
      actions={[<ArrowBackNavigationButton title="Retourner à la liste" to="/castres/greenflux/cdr/list" key={''} />]}
    />

    <div className="station-container">
      {/* Left panel */}
      <div style={{ marginRight: '1.5em' }}>
        <General cdr={cdr} />
      </div>

      {/* Right panel */}
      <div>
        <InformationCDR cdr={cdr} />
        <FactureCDR cdr={cdr} />
      </div>
    </div>
  </Fragment>
);

/*****************************************************\
 *        fonction de mise en page des blocks       * |
\*****************************************************/

/**
 * Permet l'affichage des informations général du CDR
 * @param cdr données du CDR récupéré en base
 */
const General: FC<{ cdr: WkGreenfluxCdr }> = ({ cdr }) => (
  <UI.Paper title="Général" style={{ display: 'flex', flexDirection: 'column' }} tied>
    <UI.LabelValue label="Id CDR">{cdr.idCdr}</UI.LabelValue>
    <UI.LabelValue label="Type">{cdr.type}</UI.LabelValue>
    <UI.LabelValue label="Station Greenflux">{cdr.stationGreenfluxName}</UI.LabelValue>
    <UI.LabelValue label="Référence station CMS">{cdr.stationCmsRef}</UI.LabelValue>
    <UI.LabelValue label="Création du CDR">{Utils.displayDateTime(cdr.lastUpdated, true)}</UI.LabelValue>
    <UI.LabelValue label="Référence de la note de virement">
      <NavigationButton
        title={cdr.creditNoteRef}
        to={`/castres/greenflux/NoteDeVirement/list?t-ref=${cdr.creditNoteRef}`}
        access={[ROLE.ADMIN_GF_NOTEDEVIREMENT_LIST]}
      />
      {!AccessFilter([ROLE.ADMIN_GF_NOTEDEVIREMENT_LIST]) && cdr.creditNoteRef}
    </UI.LabelValue>

    <UI.Divider />

    <UI.ChipInfo icon={<CheckCircleIcon />} label="Note de virement validée" isValid={cdr.transferedToClient} />
  </UI.Paper>
);

/**
 * Permet de faire l'affichage des données du CDR, des informations plus
 * détailler
 * @param cdr données reçu du CDR en base
 */
const InformationCDR: FC<{ cdr: WkGreenfluxCdr }> = ({ cdr }) => {
  return (
    <UI.Paper title="Informations du CDR" tied actions={[]}>
      <UI.LabelValue label="Date de début de la recharge">
        {Utils.displayDateTime(cdr.startDateTime, true)}
      </UI.LabelValue>
      <UI.LabelValue label="Date de fin de la recharge">{Utils.displayDateTime(cdr.stopDateTime, true)}</UI.LabelValue>
      <UI.LabelValue label="Type chargeur">{cdr.chargerType}</UI.LabelValue>
      <UI.LabelValue label="Token">{cdr.authId}</UI.LabelValue>
      <UI.LabelValue label="Méthode d'authentification">{cdr.authMethod}</UI.LabelValue>
      <UI.LabelValue label="EMSP">
        {cdr.emspCountryCode} - {cdr.emspPartyId}
      </UI.LabelValue>
      <UI.LabelValue label="EVSE ID">{cdr.evseId}</UI.LabelValue>
      <UI.LabelValue label="EVSE UID">{cdr.evseUid}</UI.LabelValue>
      <UI.LabelValue label="Connecteur">
        {cdr.connectorFormat} - {cdr.connectorStandard} - {(cdr.connectorMaxPower ?? 0) / 1000} kW
      </UI.LabelValue>
      <UI.LabelValue label="Alimentation">
        {cdr.connectorVoltage} V {cdr.connectorPowerType} - {cdr.connectorAmperage} A
      </UI.LabelValue>
      <UI.LabelValue label="ID du client">
        <NavigationButton
          title={cdr.customerExternalId}
          to={`/castres/greenflux/cdr/list?t-driverExternalId=${cdr.customerExternalId}`}
          access={[ROLE.ADMIN_GF_CDR_LIST]}
        />
        {!AccessFilter([ROLE.ADMIN_GF_CDR_LIST]) && cdr.customerExternalId}
      </UI.LabelValue>
      <UI.LabelValue label="ID du conducteur">
        <NavigationButton
          title={cdr.driverExternalId}
          to={`/castres/greenflux/cdr/list?t-driverExternalId=${cdr.driverExternalId}`}
          access={[ROLE.ADMIN_GF_CDR_LIST]}
        />
        {!AccessFilter([ROLE.ADMIN_GF_CDR_LIST]) && cdr.driverExternalId}
      </UI.LabelValue>
      <UI.LabelValue label="Nom de la station Greenflux">
        <NavigationButton
          title={cdr.stationGreenfluxName}
          to={`/castres/greenflux/stations/${cdr.stationGreenfluxId}/show`}
          access={[ROLE.ADMIN_GF_STATION_VIEW]}
        />
        {!AccessFilter([ROLE.ADMIN_GF_STATION_VIEW]) && cdr.stationGreenfluxName}
      </UI.LabelValue>
      <UI.LabelValue label="Référence de la station du CMS">
        <NavigationButton
          title={cdr.stationCmsRef}
          to={`/castres/client/station/${cdr.stationCmsId}/show`}
          access={[ROLE.ADMIN_PARK_STATION_LIST]}
        />
        {!AccessFilter([ROLE.ADMIN_PARK_STATION_LIST]) && cdr.stationCmsRef}
      </UI.LabelValue>
      {cdr.remark}
    </UI.Paper>
  );
};

/**
 * Permet l'affichage des informations concernant les données de facturation
 * et consommation d'energie du CDR
 * @param cdr données reçu du CDR en base
 */
const FactureCDR: FC<{ cdr: WkGreenfluxCdr }> = ({ cdr }) => {
  return (
    <UI.Paper title="Informations de facturation du CDR" tied actions={[]}>
      <Card variant="outlined" className="cdrDetailCard">
        <CardHeader title={<Typography variant="body1">Détail de la transaction</Typography>} className="cardHeader" />
        <CardContent className="cardContent">
          <span>Énergie consommée en kWh : {cdr.totalEnergy}</span>
          <span>Heures de recharge : {cdr.totalTime}</span>
          <span>Heures de parking : {cdr.totalParkingTime}</span>
          <span>Puissance maximum atteinte (en W) : {cdr.maxPowerW}</span>
          <span>
            <br />
          </span>
          <span>Devise : {cdr.currency}</span>
          <span>Coût total HT : {cdr.totalCost}</span>
          <span>Coût total TVA : {cdr.totalVat}</span>
          <span>Coût total TTC : {cdr.totalCostInclVat}</span>
          <span>TVA : {cdr.vat} %</span>
          <span>
            <br />
          </span>
          <span>Prix vente HT : {cdr.totalRetailCost}</span>
          <span>Prix vente TVA : {cdr.totalRetailVat}</span>
          <span>Prix vente TTC : {cdr.totalRetailCostInclVat}</span>
          <span>TVA : {cdr.vat} %</span>
          <span>
            <br />
          </span>
          <span>Forfait : {cdr.retailPackageId}</span>
          <span>Autorisation : {cdr.authorizationId}</span>
          <span>Hôte d'autorisation : {cdr.authHostReference}</span>
        </CardContent>
      </Card>
    </UI.Paper>
  );
};

export default CdrDetailUi;
