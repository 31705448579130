import React, { CSSProperties, FC, ReactNode, useEffect, useState } from 'react';
import './shared.scss';
import { InputUI } from './index';
import CmsIcon from './CmsIcon';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
import { IdLabel } from '../../interface/CommonType';

interface CmsImportPanelProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  children: React.ReactNode;
  panelWidth?: string;
  title: string | ReactNode;
}

export const CmsImportPanel: FC<CmsImportPanelProps> = ({ open, setOpen, children, title, panelWidth }) => {
  const style: CSSProperties = { width: panelWidth ?? '300px', right: open ? '0' : `-${panelWidth ?? '300px'}` };
  return (
    <div className="import-panel-container">
      <div className={'import-panel ' + (open ? 'open' : 'close')} style={style}>
        {!open && (
          <div className="open-button">
            <CmsIcon icon="add" onClick={() => setOpen(true)} />
          </div>
        )}
        <div className="header">
          <div className="flex-h space-between">
            {title}
            <CmsIcon icon="close" onClick={() => setOpen(false)} />
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

interface CmsImportSimpleListProps {
  optionsList: IdLabel[];
  onClick?: (option: any) => void;
  card?: (data: any) => ReactNode;
}

export const CmsImportSimpleList: FC<CmsImportSimpleListProps> = ({ optionsList, onClick, card }) => {
  const [optionList, setOptionList] = useState<IdLabel[]>(optionsList);
  const [filter, setFilter] = useState<string>();
  useEffect(() => {
    setOptionList(optionsList);
  }, [optionsList]);
  const filteredOptions = optionList.filter((x: any) => x.label.toLowerCase().includes(filter?.toLowerCase() ?? ''));
  return (
    <div className="import-panel-simple-list">
      <InputUI.DebouncedInput onChange={setFilter} />
      <div className="list-container">
        {filteredOptions.map((x: IdLabel) =>
          !onClick ? (
            <div className="flex-h" key={'import-list' + x.id}>
              - {card ? card(x) : x.label}
            </div>
          ) : (
            <div key={'import-list' + x.id} className="clickable flex-h" onClick={() => onClick(x)}>
              - {card ? card(x) : x.label}
            </div>
          ),
        )}
      </div>
    </div>
  );
};

export interface CmsTreeViewProps {
  tree: CmsTreeViewChildrenProps[];
  card: (line: CmsTreeViewChildrenProps | any, onClick: (item: any) => any) => ReactNode;
  onCardClick: (line: CmsTreeViewChildrenProps) => void;
  stateHandler?: { state: any; setState: (state: any) => void };
  multiselect?: boolean;
  checkboxSelection?: boolean;
  itemPreId?: string;
}

export interface CmsTreeViewChildrenProps {
  children?: CmsTreeViewChildrenProps[];
  id: string;
  label: ReactNode;
  treeItemSelected?: boolean;
}

export const CmsTreeView: FC<CmsTreeViewProps> = ({
  tree,
  card,
  onCardClick,
  multiselect,
  checkboxSelection,
  stateHandler,
}) => {
  const [unControlledState, setUnControlledState] = useState<any[]>([]);
  const state = stateHandler?.state ?? unControlledState;
  const setState = stateHandler?.setState ?? setUnControlledState;

  return (
    <SimpleTreeView
      selectedItems={state}
      aria-label="file system navigator"
      multiSelect={multiselect}
      expansionTrigger="iconContainer"
      checkboxSelection={checkboxSelection}
      onSelectedItemsChange={(_, list) => setState(list as any)}
      sx={{ height: '100%', flexGrow: 1, maxWidth: '100%', overflowY: 'auto' }}
    >
      {tree.map((node) => CmsTreeViewNode({ key: node.id, node, card, onCardClick }))}
    </SimpleTreeView>
  );
};

interface CmsTreeViewNodeProps {
  key: string;
  node: CmsTreeViewChildrenProps;
  onCardClick: (line: CmsTreeViewChildrenProps) => void;
  card: (line: CmsTreeViewChildrenProps, onClick: (item: any) => any) => ReactNode;
  depth?: number;
}

const CmsTreeViewNode: FC<CmsTreeViewNodeProps> = ({ node, card, onCardClick, depth = 0 }) => {
  if (!node) return <></>;
  return (
    <TreeItem key={node.id} itemId={node.id} label={card(node, onCardClick)}>
      {node.children?.map((node, i) => (
        <CmsTreeViewNode key={'node' + node.id + '-' + i} depth={depth + 1} {...{ node, card, onCardClick }} />
      ))}
    </TreeItem>
  );
};
